import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import type {IModalsStore} from "data/stores/modals/modals.store";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import {Bindings} from "data/constants/bindings";
import {IS_SECRET_ENABLED} from "data/constants";
import {ModalType} from "data/enums";

export interface ILandingController extends ViewController {
	i18n: ILocalizationStore;
	goToLogin: () => void;
	goToRegister: () => void;
	handleSecretClick: () => void;
}

@injectable()
export class LandingController implements ILandingController {
	private static _count = 0;

	static get IS_SECRET_PASSED() {
		const isSecretePassed = sessionStorage.getItem("isSecretPassed");
		return isSecretePassed ? JSON.parse(isSecretePassed) === true : !IS_SECRET_ENABLED;
	}

	constructor(
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore,
		@inject(Bindings.LocalizationStore) public i18n: ILocalizationStore
	) {}

	handleSecretClick = () => {
		if (LandingController.IS_SECRET_PASSED) {
			return;
		}

		LandingController._count += 1;

		if (LandingController._count >= 10) {
			sessionStorage.setItem("isSecretPassed", "true");
			window.location.reload();
		}
	};

	dispose(): void {
		return;
	}

	init(): void {
		return;
	}

	goToRegister = () => {
		this._modalsStore.showModal(ModalType.REGISTRATION);
	};

	goToLogin = () => {
		this._modalsStore.showModal(ModalType.LOGIN);
	};
}
