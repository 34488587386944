import {SHARE_PATH, SITE_URL} from "data/constants";
import {ShareType, SocialNetwork} from "data/enums";
import {set} from "lodash";
import {ConnextraType, createConnextraScriptTag} from "data/utils/connextra";

interface IContestShare {
	socialNetwork: SocialNetwork;
	type: ShareType.Contest;
	message?: string;
	locale: string | null;
	contestId?: number;
}

type IShare = IContestShare;

export abstract class ShareUtility {
	private static callbacks = {
		[SocialNetwork.Twitter]: (data: IShare) => ShareUtility.shareTW(data),
		[SocialNetwork.Facebook]: (data: IShare) => ShareUtility.shareFB(data),
		[SocialNetwork.Mobile]: (data: IShare) => ShareUtility.shareMobile(data),
	} as const;

	public static share(data: IShare): boolean | void | Window | null {
		// if (process.env.JEST_WORKER_ID === undefined) {
		// 	createConnextraScriptTag(ConnextraType.SHARE);
		// }
		const callback = ShareUtility.callbacks[data.socialNetwork];

		if (!callback || typeof callback !== "function") {
			throw new Error("Invalid share type");
		}

		return callback(data);
	}

	private static shareTW({message = "", ...params}: IShare): WindowProxy | null {
		createConnextraScriptTag(ConnextraType.TW_SHARE);

		return window.open(
			"https://twitter.com/share?url=" +
				encodeURIComponent(ShareUtility.getLinkForShare(params)) +
				"&text=" +
				encodeURIComponent(message),
			"twitter-share-dialog",
			"width=626,height=436"
		);
	}

	private static shareFB(data: IShare): void {
		if (!window.fbShare) {
			throw new Error("No Facebook share provider found");
		}
		const link = ShareUtility.getLinkForShare(data);
		window.fbShare(link);

		createConnextraScriptTag(ConnextraType.FB_SHARE);
	}

	private static shareMobile({message = "", ...params}: IShare) {
		try {
			void navigator.share({
				url: ShareUtility.getLinkForShare(params),
				text: message,
			});
		} catch (err) {
			// trackSentryErrors(err, {}, "shareMobile");
			const error = err as Error;
			throw new Error(error.message);
		}
		return true;
	}

	private static getLinkForShare(data: IShare): string {
		const params = {
			time: this.getTimestamp(),
			locale: data.locale,
		};
		console.log(data);
		if (data.type === ShareType.Contest) {
			set(params, "contestId", data.contestId);
		}

		const sharePath = "general";
		console.log(
			`${SITE_URL}/${SHARE_PATH}/${"en"}/${sharePath}/${ShareUtility.getEncodedJson(params)}`
		);

		return `${SITE_URL}/${SHARE_PATH}/${"en"}/${sharePath}/${ShareUtility.getEncodedJson(
			params
		)}`;
	}

	private static getTimestamp(): string {
		return Date.now().toString().substring(9);
	}

	private static getEncodedJson(object: Partial<IShare> & {time: string}): string {
		return window.btoa(JSON.stringify(object));
	}
}
