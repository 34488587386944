import React from "react";
import styled from "@emotion/styled";
import {Button, IconButton, InputAdornment, Typography} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {useViewController} from "data/services/locator";
import {IFormLoginController} from "views/components/FormLogin/formLogin.controller";
import {Bindings} from "data/constants/bindings";
import {observer} from "mobx-react";
import {Exist} from "views/components/exist/exist.component";
import {Input} from "views/components/common/input";
// import {useNavigate} from "react-router-dom";

const Form = styled.form`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	width: 100%;
`;

const ForgotPasswordLink = styled(Button)`
	font-style: normal;
	font-weight: 500;
	font-size: 15px;
	line-height: 118%;
	text-align: right;
	letter-spacing: 0.01em;
	text-transform: capitalize;
	color: #222222;
	padding: 0;
	margin-left: auto;
	margin-bottom: 12px;
`;

const RegistrationOpenLink = styled(ForgotPasswordLink)`
	text-align: center;
	margin-left: initial;
	margin-top: 16px;
`;

export const FormLoginComponent: React.FC = observer(() => {
	const {
		isLoading,
		isPasswordVisible,
		error,
		togglePasswordVisibility,
		handleLoginForm,
		resetFormErrors,
		openForgotPasswordModal,
		goToRegister,
	} = useViewController<IFormLoginController>(Bindings.FormLoginController);

	return (
		<Form onSubmit={handleLoginForm} onChange={resetFormErrors}>
			<Exist when={Boolean(error)}>
				<Typography sx={{marginBottom: "15px", color: "#f44336"}}>{error}</Typography>
			</Exist>
			<Input
				name="email"
				label="Email Address"
				placeholder="Email"
				type="email"
				required
				InputLabelProps={{shrink: true}}
			/>
			<Input
				name="password"
				label="Password"
				placeholder="Password"
				type={isPasswordVisible ? "text" : "password"}
				InputProps={{
					endAdornment: (
						<InputAdornment position="end">
							<IconButton
								aria-label="toggle password visibility"
								onClick={togglePasswordVisibility}
								edge="end">
								<VisibilityIcon />
							</IconButton>
						</InputAdornment>
					),
				}}
				required
				InputLabelProps={{shrink: true}}
			/>
			<ForgotPasswordLink onClick={openForgotPasswordModal}>
				Forgot Password?
			</ForgotPasswordLink>
			<Button variant="contained" type="submit" disabled={isLoading || Boolean(error)}>
				<span>LOG IN</span>
			</Button>
			<RegistrationOpenLink onClick={goToRegister}>New? Register Now</RegistrationOpenLink>
		</Form>
	);
});
