import {useViewController} from "data/services/locator/locator_hook.service";
import {observer} from "mobx-react";
import React, {Fragment, useEffect} from "react";
import {NavLink, Outlet, useParams} from "react-router-dom";
import {ContestsDropdown} from "views/components/contest/contests_dropdown/contests_dropdown.component";
import {
	Container,
	PageHeader,
	PageHeaderContainer as BasePageHeaderContainer,
} from "views/components/structure/structure.component";
import {IContestController} from "views/controllers/contest/contest.controller";
import InfoIcon from "@mui/icons-material/Info";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import {Avatar as MUIAvatar, Box, Stack, Typography} from "@mui/material";
import {Exist} from "views/components/exist/exist.component";
import styled from "@emotion/styled";
import {
	ContestShare,
	ShareDirection,
} from "views/components/contest/contest_share/contest_share.component";
import {ContestUtils} from "data/utils/contest_utils";
import ShareIcon from "@mui/icons-material/Share";
import {IContestRouteParams} from "data/types/global";
import {Bindings} from "data/constants/bindings";
import {IContestRootController} from "./contest_root.controller";

const Avatar = styled(MUIAvatar)`
	cursor: pointer;
	color: #000;
	background: #ffffff;
` as typeof MUIAvatar;

const PointsBox = styled(MUIAvatar)`
	background: #ffffff;
	color: #c69214;
	text-transform: uppercase;
	font-family: var(--fontFamilySecond);
	border-radius: 90px;
	min-width: 65px;
	width: auto;
	font-size: 14px;
	font-weight: 700;
`;

const HeaderButtonsBox = styled.div`
	display: flex;
	align-items: center;
	gap: 10px;

	@media (max-width: 640px) {
		justify-content: flex-start;
	}
`;

const PageHeaderContainer = styled(BasePageHeaderContainer)`
	@media (max-width: 870px) {
		padding-left: 20px;
		padding-right: 20px;
	}
`;

const SContainer = styled(Container)`
	z-index: 6;
`;

export const ContestRoot = observer(() => {
	const contestID = Number(useParams<IContestRouteParams>().id);
	const controller = useViewController<IContestController>(Bindings.ContestController, {
		contestID,
	});

	const {currentContest, allContests, updateContestID, i18n, contestQuestions} = controller;
	const isComplete = currentContest ? ContestUtils.isComplete(currentContest) : false;
	const totalPoints = ContestUtils.getTotalPoints(contestQuestions);
	const isShareAvailable = currentContest
		? ContestUtils.isScheduled(currentContest) && !!currentContest.questions.length
		: false;

	const userController = useViewController<IContestRootController>(
		Bindings.ContestRootController
	);

	const {user} = userController;

	useEffect(() => {
		void updateContestID(contestID);
	}, [contestID, updateContestID]);

	return (
		<Fragment>
			<PageHeader>
				<PageHeaderContainer>
					<h1 style={{textTransform: "uppercase"}}>{user?.username ?? ""}</h1>
					<Box sx={{height: 6}} />
					<HeaderButtonsBox>
						<Stack direction="row" alignItems="center" gap={1.5}>
							<Exist when={!isComplete}>
								<Typography sx={{fontWeight: 500}}>
									{i18n.t("contest.how_to_play.title", "How to play")}
								</Typography>
								<Avatar component={NavLink} to="/help">
									<InfoIcon color={"primary"} />
								</Avatar>
							</Exist>
							<Exist when={isComplete}>
								<PointsBox>
									{totalPoints} {i18n.t("contest.points.label", "PTS")}
								</PointsBox>
								<Avatar component={NavLink} to={`/standings/${contestID}`}>
									<EmojiEventsIcon />
								</Avatar>
							</Exist>
						</Stack>
						<Exist when={isShareAvailable}>
							<ContestShare
								message={i18n.t("share.twitter.contest_text", {
									contest_name: currentContest?.name,
								})}
								component={
									<Avatar>
										<ShareIcon color={"primary"} />
									</Avatar>
								}
								direction={ShareDirection.FromRight}
								mobileDirection={ShareDirection.FromLeft}
							/>
						</Exist>
					</HeaderButtonsBox>
				</PageHeaderContainer>
			</PageHeader>
			<SContainer>
				<ContestsDropdown currentContest={currentContest} allContests={allContests} />
			</SContainer>
			<Outlet context={controller} />
		</Fragment>
	);
});
