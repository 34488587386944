import styled from "@emotion/styled";
import {Button} from "@mui/material";

export const Container = styled.div`
	padding: 20px;
	position: relative;
`;

export const AuthContainer = styled(Container)`
	padding: 68px 20px 150px 20px;
	z-index: 2;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;

	background: #ffffff;
	background-size: contain;
	background-repeat: no-repeat;
	background-position: 0 bottom;

	overflow: auto;
	max-width: 420px;
	margin: 0 auto;

	&.full-screen {
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 1;
	}

	> img {
		height: 90px;
		width: auto;
		margin-bottom: 8px;
	}

	h1 {
		font-style: normal;
		font-weight: 700;
		font-size: 20px;
		line-height: 118%;
		text-align: center;
		letter-spacing: 0.01em;
		text-transform: uppercase;
		margin-bottom: 28px;
	}

	a.close {
		position: absolute;
		top: 20px;
		right: 20px;
		width: 24px;
		height: 24px;
		display: flex;
		justify-content: center;
		align-items: center;
	}
`;

export const ModalContent = styled.div`
	position: fixed;
	left: 20px;
	right: 20px;
	top: 80px;
	max-height: calc(100vh - 120px);
	max-width: 420px;
	// Disabled as incorrect behaviour on mobile
	//width: 100%;
	margin: 0 auto;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	border-radius: 8px;
	background: #ffffff;
	overflow: auto;

	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	padding: 68px 20px 20px 20px;
	gap: 12px;
	outline: none;

	&.full-screen {
		max-width: 420px;
		-webkit-border-radius: 0;
		-moz-border-radius: 0;
		border-radius: 0;
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
	}

	&.iframe-content {
		left: 20px;
		right: 20px;
		top: 80px;
		bottom: 20px;
		padding-top: 52px;
	}

	h4 {
		font-style: normal;
		font-weight: 500;
		font-size: 16px;
		line-height: 20px;
		letter-spacing: 0.2px;
		color: #121212;
	}
`;

export const ModalCloseButton = styled(Button)`
	border-radius: 0;
	position: absolute;
	right: 20px;
	top: 20px;
	width: 24px;
	height: 24px;
	min-width: 24px;
	padding: 0;
	color: #000;
	&:hover {
		color: #000;
	}
`;

export const ModalTitle = styled.h3`
	font-style: normal;
	font-weight: 700;
	font-size: 20px;
	line-height: 118%;
	text-align: center;
	letter-spacing: 0.01em;
	text-transform: uppercase;
	color: #222222;
	margin: 10px 0;
`;

export const ModalText = styled.p`
	font-style: normal;
	font-weight: 500;
	font-size: 15px;
	line-height: 118%;
	color: #222222;
	text-align: center;
	letter-spacing: 0.01em;
`;

export const ModalButton = styled(Button)`
	max-width: 420px;
	width: 100%;
	margin-top: 12px;
`;
