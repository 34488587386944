export const DEFAULT_CHIPS_STEP = 1;

export const API_URL = process.env.REACT_APP_API_URL || "";
export const JSON_URL = process.env.REACT_APP_JSON_URL || "";
export const CONTENT_JSON_URL = process.env.REACT_APP_CONTENT_JSON_URL || "";
export const BASE_URL = process.env.REACT_APP_BASE_URL || "/";
export const SENTRY_DSN_URL = process.env.REACT_APP_SENTRY_DSN_URL;
export const SHARE_URL = process.env.REACT_APP_SHARE_URL || "";
export const IMAGES_URL = process.env.REACT_APP_IMAGES_URL || "";
export const SITE_URL = window.location.origin || "";
export const SHARE_PATH = process.env.REACT_APP_SHARE_PATH || "";

export const STORAGE_VERSION = 1;
export const EMAIL_REGEXP = "\\S+@\\S+\\.\\S+";
export const PASSWORD_PATTERN =
	"^((?=.*?\\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[-!@#$%^&*()_+|~=`{}\\[\\]:\";'<>?,.\\/])).{8,}$";

export const PASSWORD_REQUIREMENTS =
	"Password must include 8 characters, including 1 upper case character, 1 number and 1 special character";

export const DEFAULT_NO_TEAM_KEY = null;
export const BASE_FONT = "";
export const IS_SECRET_ENABLED = true;
export const CONTEST_ROUTES = [{path: "/contest/:id"}, {path: "/contest/:id/result"}];
export const DATE_FORMAT_BY_LOCALE = {
	en: "MMM-dd h:mma ZZZZ",
	fr: "dd MMM à HH:mm ZZZZ",
};
export const FORM_VALIDATION_ELEMENT_CLASSNAME = "form-field-validate" as const;
export const DEFAULT_WAGER = 30;
export const PROP_TIME_FORMAT = "L/d h:mm a ZZZZ";

export const EMAIL_PATTERN = "[A-za-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$";
