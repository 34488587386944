import type {ComponentType} from "react";
import type {AxiosError} from "axios";
import type {IApiResponse} from "data/services/http";
import {IAxiosApiError, IAxiosErrorMessageObject} from "data/types/api";

type Factory<T> = () => Promise<{
	default: ComponentType<T>;
}>;

export function retryFailLoad<T>(fn: Factory<T>, retriesLeft = 5, interval = 1000): Factory<T> {
	return () =>
		new Promise((resolve, reject) => {
			fn()
				.then(resolve)
				.catch((error: unknown) => {
					setTimeout(() => {
						if (retriesLeft === 1) {
							reject(error);
							return;
						}

						retryFailLoad(fn, retriesLeft - 1, interval)().then(resolve, reject);
					}, interval);
				});
		});
}

export const extractErrorMessage = (error: AxiosError<IApiResponse>) =>
	error.response?.data?.errors?.[0]?.message || error.message;

export function getErrorMessageFromAxiosResponse(event: AxiosError<IAxiosApiError, unknown>) {
	if (!event) {
		return "";
	}

	if (!event.response?.data?.errors) {
		return event.message;
	}

	return Object.values(event.response?.data?.errors)
		.map((e: IAxiosErrorMessageObject) => e.message)
		.join(" \n");
}

export function isBoolean<T>(value: T): boolean {
	return value === "true" || value === "false";
}

export function parseStringToBoolean(value: string): boolean {
	return value === "true";
}

export function getByIndex<T>(array: T[] = [], index: number) {
	const isIndexExist = index >= 0 && array.length > index;
	return isIndexExist ? array[index] : undefined;
}

export * from "data/utils/countdown";
export * from "data/utils/socialShare";
