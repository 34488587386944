import React, {useEffect, useRef} from "react";
import styled from "@emotion/styled";
import {useViewController} from "data/services/locator";
import {IFormRegistrationController} from "views/components/FormRegistration/formRegistration.controller";
import {Bindings} from "data/constants/bindings";
import {Exist} from "views/components/exist/exist.component";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import {
	Button,
	Checkbox,
	FormControlLabel,
	IconButton,
	InputAdornment,
	MenuItem, Typography,
} from "@mui/material";
import {observer} from "mobx-react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {Input} from "views/components/form";
import {createConnextraScriptTag, ConnextraType} from "data/utils/connextra";

const Form = styled.form`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	gap: 12px;
	margin-top: 12px;

	.MuiFormControlLabel-root {
		width: 100%;
		.MuiTypography-root.is-required:after {
			content: " * ";
			color: red;
		}
		.MuiFormControlLabel-asterisk {
			display: none;
		}
	}
	.MuiFormHelperText-root {
		font-family: var(--fontFamilyRedHat);
	}
	overflow-y: auto;
	overflow-x: hidden;
	padding: 20px 20px;
`;

const LoginOpenLink = styled(Button)`
	font-style: normal;
	font-weight: 500;
	font-size: 15px;
	line-height: 118%;
	text-align: right;
	letter-spacing: 0.01em;
	text-transform: capitalize;
	color: #222222;
	padding: 0;
	margin-bottom: 12px;
`;
export const FormRegistration: React.FC = observer(() => {
	const {
		i18n,
		form,
		squads,
		isLoading,
		isPasswordVisible,
		isConfirmPasswordVisible,
		handleFormChange,
		handleSelectChange,
		handleFormSubmit,
		togglePasswordVisibility,
		toggleConfirmPasswordVisibility,
		goToLogin,
		formErrors,
		error
	} = useViewController<IFormRegistrationController>(Bindings.FormRegistrationController);

	const labelEmail = i18n.t("registration.email.label", "Email");
	const placeholderEmail = String(
		i18n.t("registration.email.email_placeholder", "Email Address")
	);
	const labelFavTeam = i18n.t("form.registration.fav_team", "Favorite Team");
	const placeholderFavTeam = String(
		i18n.t("form.registration.fav_team_default", "- Select Team -")
	);
	const labelFirstName = i18n.t("registration.first_name.label", "First Name");
	const labelLastName = i18n.t("registration.last_name.label", "Last Name");
	const labelDisplayName = i18n.t("registration.display_name.label", "Display name");
	const labelPassword = i18n.t("registration.password.label", "Password");
	const labelConfirmPassword = i18n.t("registration.confirm_password.label", "Confirm Password");

	const ref = useRef<HTMLFormElement | null>(null);
	// const ref = useRef<HTMLFormElement | null>(null);

	useEffect(() => {
		createConnextraScriptTag(ConnextraType.REGISTRATION_START);
	}, []);

	return (
		<Form ref={ref} onSubmit={handleFormSubmit} onChange={handleFormChange}>
			<Input
				fullWidth
				name="email"
				label={labelEmail}
				placeholder={placeholderEmail}
				value={form.email}
				required
				error={Boolean(formErrors["email"])}
				helperText={formErrors["email"]}
				InputLabelProps={{shrink: true}}
			/>
			<PhoneInput
				country="us"
				inputProps={{
					name: "mobile",
					required: false,
				}}
				inputStyle={{
					width: "100%",
					height: "56px",
				}}
			/>
			<Input
				fullWidth
				name="password"
				label={labelPassword}
				placeholder={String(i18n.t(""))}
				type={isPasswordVisible ? "text" : "password"}
				InputProps={{
					endAdornment: (
						<InputAdornment position="end">
							<IconButton
								aria-label="toggle password visibility"
								onClick={togglePasswordVisibility}
								edge="end">
								<VisibilityIcon />
							</IconButton>
						</InputAdornment>
					),
				}}
				required
				value={form.password}
				InputLabelProps={{shrink: true}}
				error={Boolean(formErrors["password"])}
				helperText={i18n.t(
					"form.common.password_requirements",
					"Min 8 characters, one uppercase letter, one number, and one special character"
				)}
			/>
			<Input
				fullWidth
				name="confirmPassword"
				label={labelConfirmPassword}
				placeholder={labelPassword}
				required
				value={form.confirmPassword}
				InputLabelProps={{shrink: true}}
				type={isConfirmPasswordVisible ? "text" : "password"}
				InputProps={{
					endAdornment: (
						<InputAdornment position="end">
							<IconButton
								aria-label="toggle password visibility"
								onClick={toggleConfirmPasswordVisibility}
								edge="end">
								<VisibilityIcon />
							</IconButton>
						</InputAdornment>
					),
				}}
			/>
			<Input
				fullWidth
				name="firstName"
				label={labelFirstName}
				placeholder={String(labelFirstName)}
				required
				value={form.firstName}
				InputLabelProps={{shrink: true}}
			/>
			<Input
				fullWidth
				name="lastName"
				label={labelLastName}
				placeholder={String(labelLastName)}
				required
				value={form.lastName}
				InputLabelProps={{shrink: true}}
			/>

			<Input
				fullWidth
				name="username"
				label={labelDisplayName}
				placeholder={String(labelDisplayName)}
				required
				value={form.username}
				InputLabelProps={{shrink: true}}
				error={Boolean(formErrors["username"])}
				helperText={formErrors["username"]}
			/>

			<Input
				select
				fullWidth
				name="favoriteSquadId"
				label={labelFavTeam}
				onChange={handleSelectChange}
				defaultValue={0}
				InputLabelProps={{shrink: true}}
				required
				error={Boolean(formErrors["favoriteSquadId"])}
				helperText={formErrors["favoriteSquadId"]}>
				<MenuItem value={0} defaultChecked>
					{placeholderFavTeam}
				</MenuItem>
				{squads.map((squad) => (
					<MenuItem key={squad.id} value={squad.id}>
						{squad.name}
					</MenuItem>
				))}
				<MenuItem value={999999}>I don’t have a Favorite Team yet</MenuItem>
			</Input>

			<FormControlLabel
				control={<Checkbox name="terms" required />}
				label={i18n.t(
					"form.registration.terms",
					"By selecting this box I agree to the Terms and Conditions"
				)}
				slotProps={{typography: {className: "is-required"}}}
			/>
			<FormControlLabel
				control={<Checkbox name="ageOptin" required />}
				label={i18n.t(
					"form.registration.geo",
					"Yes, I am the legal age of majority in my province/territory of residence."
				)}
				slotProps={{typography: {className: "is-required"}}}
			/>
			<FormControlLabel
				control={<Checkbox name="marketingOptin" checked={form.marketingOptin} />}
				label={i18n.t(
					"form.registration.ticket_opt",
					"Yes, I would like to receive breaking news, " +
						"League updates and exclusive offers from the SlamBall League and/or its partners. You can withdraw " +
						"your consent at any time by unsubscribing."
				)}
			/>

			<Exist when={error}>
				<Typography color={'error'}>{error}</Typography>
			</Exist>

			<Button type="submit" variant="contained" disabled={isLoading}>
				<Exist when={!isLoading}>
					<span>{i18n.t("form.registration.register", "Register")}</span>
				</Exist>
			</Button>
			<LoginOpenLink onClick={goToLogin}>Already have an account? Log In</LoginOpenLink>
		</Form>
	);
});
