import {Container, ContainerModule, type interfaces} from "inversify";
import {Bindings} from "data/constants/bindings";
import {CONTENT_JSON_URL, API_URL, JSON_URL} from "data/constants";
import {type IUserStore, UserStore} from "data/stores/user/user.store";
import {AuthController, type IAuthController} from "views/controllers/auth/auth.controller";
import {
	type IMyAccountController,
	MyAccountController,
} from "views/pages/my_account/my_account.controller";
import {type ILoginController, LoginController} from "views/pages/login/login.controller";
import {CountriesStore, type ICountriesStore} from "data/stores/countries/countries.store";
import {type IRoundsStore, RoundsStore} from "data/stores/rounds/rounds.store";
import {type IPlayersStore, PlayersStore} from "data/stores/players/players.store";
import {type ISquadsStore, SquadsStore} from "data/stores/squads/squds.store";
import {
	type ISecretGateController,
	SecretGateController,
} from "views/controllers/secrete_gate/secret_gate.controller";
import {
	type ILiveScoreController,
	LiveScoreController,
} from "views/controllers/live_score/live_score.controller";
import {ChecksumStore, type IChecksumStore} from "data/stores/checksum/checksum.store";
import {
	type IRegistrationController,
	RegistrationController,
} from "views/pages/registration/registration.controller";
import {type IUserApiProvider, UserApiProvider} from "data/providers/api/user.api.provider";
import {
	type IPasswordApiProvider,
	PasswordApiProvider,
} from "data/providers/api/password.api.provider";
import {
	ForgotPasswordController,
	type IForgotPasswordController,
} from "views/pages/forgot_password/forgot_password.controller";
import {
	type IResetPasswordController,
	ResetPasswordController,
} from "views/pages/reset_password/reset_password.controller";
import {
	type ISessionController,
	SessionController,
} from "views/components/session/session.controller";
import {
	BootstrapController,
	type IBootstrapController,
} from "views/components/bootstrap/bootstrap.controller";
import {
	IModalLoginController,
	ModalLoginController,
} from "views/components/modals/ModalLogin/modalLogin.controller";
import {
	IModalRegistrationController,
	ModalRegistrationController,
} from "views/components/modals/ModalRegistration/modalRegistration.controller";
import {
	FormLoginController,
	IFormLoginController,
} from "views/components/FormLogin/formLogin.controller";

import {
	IRangeSliderController,
	RangeSliderController,
} from "views/components/RangeSlider/rangeSlider.controller";

import {
	FormRegistrationController,
	IFormRegistrationController,
} from "views/components/FormRegistration/formRegistration.controller";
import {type IJSONProvider, JSONProvider} from "data/providers/json/json.provider";
import {AuthApiProvider, type IAuthApiProvider} from "data/providers/api/auth.api.provider";
import {HttpClientFactory, type IHttpClientService} from "data/services/http";
import {
	type IStandingsApiProvider,
	StandingsApiProvider,
} from "data/providers/api/standings.api.provider";

import {IStandingsStore, StandingsStore} from "data/stores/standings/standings.store";

import {
	type IStandingsController,
	StandingsController,
} from "views/components/standings/standings.controller";

import {type IModalsStore, ModalsStore} from "data/stores/modals/modals.store";
import {HomeController, type IHomeController} from "views/pages/home/home.controller";
import {
	type ILocalizationStore,
	LocalizationStore,
} from "data/stores/localization/localization.store";
import {
	type ILocalizationController,
	LocalizationController,
} from "views/controllers/localization/localization.controller";
import {
	ContestController,
	type IContestController,
} from "views/controllers/contest/contest.controller";
import {
	ContestBannerController,
	type IContestBannerController,
} from "views/components/contest/contest_banner/contest_banner.contoller";
import {
	type IMyAccountDetailsController,
	MyAccountDetailsController,
} from "views/components/my_account/my_account_details/my_account_details.controller";
import {type ILandingController, LandingController} from "views/pages/landing/landing.controller";
import {
	ContestQuestionCardController,
	type IContestQuestionCardController,
} from "views/components/contest/contest_question_card/contest_question_card.controller";

import {HelpController, IHelpController} from "views/pages/help/help.controller";
import {
	HelpListController,
	type IHelpListController,
} from "views/components/help/help_list/help_list.controller";
import {
	ContactUsController,
	type IContactUsController,
} from "views/components/help/contact_us/contact_us.controller";
import {
	type IStaticContentStore,
	StaticContentStore,
} from "data/stores/static_content/static_content.store";
import {type IPrizesController, PrizesController} from "views/pages/prizes/prizes.controller";
import {
	ContestRootController,
	IContestRootController,
} from "views/components/contest/contest_root/contest_root.controller";
import {IModalsController, ModalsController} from "views/components/modals/modals.controller";
import {GameplayStore, type IGameplayStore} from "data/stores/gameplay/gameplay.store";
import {
	GameplayAPIProvider,
	type IGameplayApiProvider,
} from "data/providers/api/gameplay.api.provider";
import {FormValidationHelper, type IFormValidationHelper} from "data/utils/form_validation_helper";
import {FormValidator, IFormValidator} from "data/utils/helpers/validators/FormValidator";

import {HeaderController, type IHeaderController} from "views/components/header/header.controller";

export const DIContainer = new Container();

export const services = new ContainerModule((bind: interfaces.Bind) => {
	bind<IHttpClientService>(Bindings.ApiHTTPClient).toConstantValue(
		HttpClientFactory.createApiClient({
			baseURL: API_URL,
			withCredentials: true,
		})
	);

	bind<IHttpClientService>(Bindings.JsonHTTPClient).toConstantValue(
		HttpClientFactory.createJSONClient({
			baseURL: JSON_URL,
		})
	);

	bind<IHttpClientService>(Bindings.ContentJsonHTTPClient).toConstantValue(
		HttpClientFactory.createJSONClient({
			baseURL: CONTENT_JSON_URL,
		})
	);
});

export const providers = new ContainerModule((bind: interfaces.Bind) => {
	bind<IJSONProvider>(Bindings.JSONProvider).to(JSONProvider);
	bind<IAuthApiProvider>(Bindings.AuthApiProvider).to(AuthApiProvider);
	bind<IUserApiProvider>(Bindings.UserApiProvider).to(UserApiProvider);
	bind<IPasswordApiProvider>(Bindings.PasswordApiProvider).to(PasswordApiProvider);
	bind<IGameplayApiProvider>(Bindings.GameplayApiProvider).to(GameplayAPIProvider);
	bind<IStandingsApiProvider>(Bindings.StandingsApiProvider).to(StandingsApiProvider);
});

export const stores = new ContainerModule((bind: interfaces.Bind) => {
	bind<ILocalizationStore>(Bindings.LocalizationStore).to(LocalizationStore).inSingletonScope();
	bind<IUserStore>(Bindings.UserStore).to(UserStore).inSingletonScope();
	bind<IRoundsStore>(Bindings.RoundsStore).to(RoundsStore).inSingletonScope();
	bind<IPlayersStore>(Bindings.PlayersStore).to(PlayersStore).inSingletonScope();
	bind<ISquadsStore>(Bindings.SquadsStore).to(SquadsStore).inSingletonScope();
	bind<IModalsStore>(Bindings.ModalsStore).to(ModalsStore).inSingletonScope();
	bind<IGameplayStore>(Bindings.GameplayStore).to(GameplayStore).inSingletonScope();

	bind<ICountriesStore>(Bindings.CountriesStore).to(CountriesStore);
	bind<IStaticContentStore>(Bindings.StaticContentStore).to(StaticContentStore);

	bind<IChecksumStore>(Bindings.ChecksumStore).to(ChecksumStore);

	bind<IStandingsStore>(Bindings.StandingsStore).to(StandingsStore);
});

export const controllers = new ContainerModule((bind: interfaces.Bind) => {
	bind<IAuthController>(Bindings.AuthController).to(AuthController);
	bind<ILoginController>(Bindings.LoginController).to(LoginController);
	bind<IForgotPasswordController>(Bindings.ForgotPasswordController).to(ForgotPasswordController);
	bind<IResetPasswordController>(Bindings.ResetPasswordController).to(ResetPasswordController);
	bind<IRegistrationController>(Bindings.RegistrationController).to(RegistrationController);
	bind<IMyAccountController>(Bindings.MyAccountController).to(MyAccountController);
	bind<ISecretGateController>(Bindings.SecretGateController).to(SecretGateController);
	bind<IHelpController>(Bindings.HelpController).to(HelpController);
	bind<IHelpListController>(Bindings.HelpListController).to(HelpListController);
	bind<IContactUsController>(Bindings.ContactUsController).to(ContactUsController);
	bind<ILiveScoreController>(Bindings.LiveScoreController).to(LiveScoreController);
	bind<ISessionController>(Bindings.SessionController).to(SessionController);
	bind<IBootstrapController>(Bindings.BootstrapController).to(BootstrapController);
	bind<IModalLoginController>(Bindings.ModalLoginController).to(ModalLoginController);
	bind<IModalRegistrationController>(Bindings.ModalRegistrationController).to(
		ModalRegistrationController
	);
	bind<IFormLoginController>(Bindings.FormLoginController).to(FormLoginController);
	bind<IFormRegistrationController>(Bindings.FormRegistrationController).to(
		FormRegistrationController
	);

	bind<IModalsController>(Bindings.ModalsController).to(ModalsController);
	bind<IHomeController>(Bindings.HomeController).to(HomeController);
	bind<IHeaderController>(Bindings.HeaderController).to(HeaderController);
	bind<ILocalizationController>(Bindings.LocalizationController).to(LocalizationController);
	bind<IContestController>(Bindings.ContestController).to(ContestController);
	bind<IContestBannerController>(Bindings.ContestBannerController).to(ContestBannerController);
	bind<IMyAccountDetailsController>(Bindings.MyAccountDetailsController).to(
		MyAccountDetailsController
	);

	bind<ILandingController>(Bindings.LandingController).to(LandingController);
	bind<IContestQuestionCardController>(Bindings.ContestQuestionCardController).to(
		ContestQuestionCardController
	);

	bind<IStandingsController>(Bindings.StandingsController).to(StandingsController);
	bind<IFormValidator>(Bindings.FormValidator).to(FormValidator);

	bind<IPrizesController>(Bindings.PrizesController).to(PrizesController);
	bind<IContestRootController>(Bindings.ContestRootController).to(ContestRootController);
	bind<IRangeSliderController>(Bindings.RangeSliderController).to(RangeSliderController);
});
export const helpers = new ContainerModule((bind: interfaces.Bind) => {
	bind<IFormValidationHelper>(Bindings.FormValidationHelper).to(FormValidationHelper);
});
