import {inject, injectable} from "inversify";
import {computed, makeAutoObservable, observable, runInAction, toJS} from "mobx";
import {Bindings} from "data/constants/bindings";
import type {IJSONProvider} from "data/providers/json/json.provider";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import {includes, toLower} from "lodash";
import {HelpPagesEnum} from "data/enums";

export type IHelpPages = Record<HelpPagesEnum, IHelpPage[]>;

export interface IHelpPage {
	id: number;
	type?: string;
	title: string;
	text: string;
	game: string;
}

export interface IStaticContent {
	page: number;
	previous_page: null;
	next_page: null;
	per_page: number;
	page_count: number;
	count: number;
	sort_by: string;
	sort_order: string;
}

interface ISection {
	id: number;
	url: string;
	html_url: string;
	category_id: number;
	position: number;
	sorting: string;
	created_at: string;
	updated_at: string;
	name: string;
	description: string;
	locale: string;
	source_locale: string;
	outdated: boolean;
	parent_section_id: null;
	theme_template: string;
}

export interface ISections extends IStaticContent {
	sections: ISection[];
}

export interface ICategories extends IStaticContent {
	categories: {
		id: number;
		url: string;
		html_url: string;
		position: number;
		created_at: string;
		updated_at: string;
		name: string;
		description: string;
		locale: string;
		source_locale: string;
		outdated: boolean;
	}[];
}
export interface IArticle {
	id: number;
	url: string;
	html_url: string;
	author_id: number;
	comments_disabled: boolean;
	draft: boolean;
	promoted: boolean;
	position: number;
	vote_sum: number;
	vote_count: number;
	section_id: number;
	created_at: string;
	updated_at: string;
	name: string;
	title: string;
	source_locale: string;
	locale: string;
	outdated: boolean;
	outdated_locales: unknown[];
	edited_at: string;
	user_segment_id: null;
	permission_group_id: number;
	label_names: string[];
	body: string;
}
export interface IArticles extends IStaticContent {
	articles: IArticle[];
}

export type TArticle = IArticles["articles"][number];

export interface IStaticContentStore {
	get sections(): ISections["sections"];

	get articles(): IArticles["articles"];

	get prizes(): IArticle | undefined;

	fetchStaticContent(): Promise<void>;
}

@injectable()
export class StaticContentStore implements IStaticContentStore {
	@observable private _helpSections?: ISections["sections"];
	@observable private _helpComponents?: IArticles["articles"];

	@observable private _prizeSection?: ISection;
	@observable private _prizeComponent?: IArticle;

	constructor(
		@inject(Bindings.LocalizationStore) private readonly _localizationStore: ILocalizationStore,
		@inject(Bindings.JSONProvider) private readonly _jsonProvider: IJSONProvider
	) {
		makeAutoObservable(this);
	}

	@computed get sections() {
		return this._helpSections ?? [];
	}

	@computed get articles() {
		return this._helpComponents ?? [];
	}

	public async fetchStaticContent() {
		const locale = (this._localizationStore.locale || "en-US").toLowerCase();

		const [categories, sections, articles] = await Promise.all([
			this._jsonProvider.helpCategories(locale),
			this._jsonProvider.helpSections(locale),
			this._jsonProvider.helpArticles(locale),
		]);

		const helpComponentsId = categories.data.categories.find(({name}) =>
			includes(toLower(name), "help")
		)?.id;

		const prizesComponentsId = categories.data.categories.find(({name}) =>
			includes(toLower(name), "prizes")
		)?.id;

		console.log(prizesComponentsId);

		runInAction(() => {
			this._helpSections = sections.data.sections.filter((section) => {
				return section.category_id === helpComponentsId;
			});
			this._helpComponents = articles.data.articles;

			this._prizeSection = sections.data.sections.find((section) => {
				return section.category_id === prizesComponentsId;
			});
			this._prizeComponent = articles.data.articles?.find(article => article.section_id === this._prizeSection?.id);
			console.log(toJS(this._prizeComponent));
		});
	}

	get prizes() {
		// console.log(this._helpComponents);
		return this._prizeComponent;
	}
}
