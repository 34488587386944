import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {
	action,
	computed,
	makeAutoObservable,
	observable,
	reaction,
	when,
	runInAction,
	toJS,
} from "mobx";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {ModalType, RequestState} from "data/enums";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {IGameplayStore} from "data/stores/gameplay/gameplay.store";
import {find, isEmpty, isEqual, size} from "lodash";

import {type IStepper, Stepper} from "data/utils/stepper/stepper.utility";
import type {IUser, IUserStore} from "data/stores/user/user.store";
import {Bindings} from "data/constants/bindings";
import {IContest, IQuestionIntro, IQuestionWithAnswers} from "data/types/contests";
import {QuestionUtils} from "data/utils/question_utils";
import {Storage} from "data/utils/Storage";

import {AxiosError} from "axios";
import {createConnextraScriptTag, ConnextraType} from "data/utils/connextra";

interface IControllerProps {
	contestID: number;
}

export interface IContestController extends ViewController<IControllerProps> {
	get i18n(): ILocalizationStore;

	get requestState(): RequestState;

	get allContests(): IContest[];

	get activeContests(): IContest[];

	get currentContest(): IContest | null;

	get contestQuestions(): IQuestionWithAnswers[] | undefined;

	get contestQuestionsWithIntros(): (IQuestionWithAnswers | IQuestionIntro)[];

	get isLoading(): boolean;

	get isQuestionsLoading(): boolean;

	get isAuthorized(): boolean;

	get buttonCopy(): string;
	get user(): IUser;
	goToLogin: () => void;

	updateContestID: (contestID: number) => void;
	setContestComplete: (contestID: number) => void;
	stepper: IStepper;
	onCompleteClick: () => void;
	handleQuestionAnswer: (questionId: number, optionId: number, range?: number | null) => void;
}

@injectable()
export class ContestController implements IContestController {
	@observable private _contestID!: number;
	@observable private _requestState: RequestState = RequestState.IDLE;
	@observable public stepper = new Stepper();
	private _questionsReactionDisposer!: ReturnType<typeof when>;

	get isAuthorized(): boolean {
		return this._userStore.isAuthorized;
	}
	get buttonCopy() {
		return this.currentContest?.status === "scheduled" ? "Edit Picks" : "ViewPicks";
	}
	get requestState() {
		return this._requestState;
	}

	get allContests() {
		return this._gameplayStore.allContests;
	}

	get activeContests() {
		return this._gameplayStore.scheduledContests;
	}

	get isLoading() {
		return isEqual(this._requestState, RequestState.PENDING);
	}

	get isQuestionsLoading() {
		return this.isLoading && isEmpty(this.contestQuestions);
	}

	get user() {
		return this._userStore.user!;
	}

	@computed get contestQuestions(): IQuestionWithAnswers[] {
		return this._gameplayStore.getQuestionsByContestID(this._contestID);
	}

	@computed get contestQuestionsWithIntros(): (IQuestionIntro | IQuestionWithAnswers)[] {
		const questions = this.contestQuestions;
		const ar: (IQuestionIntro | IQuestionWithAnswers)[] = [];
		questions.forEach((question) => {
			if (question.intro) {
				ar.push(question.intro);
				ar.push(question);
			} else {
				ar.push(question);
			}
		});
		return ar;
	}

	@computed get contestQuestionsIntros(): (IQuestionIntro | null)[] {
		return this._gameplayStore.getQuestionsIntrosByContestID(this._contestID);
	}

	@computed get currentContest() {
		return find(this._gameplayStore.allContests, {id: this._contestID}) || null;
	}

	constructor(
		@inject(Bindings.ModalsStore) private readonly _modalsStore: IModalsStore,
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.GameplayStore) private readonly _gameplayStore: IGameplayStore,
		@inject(Bindings.UserStore) private readonly _userStore: IUserStore
	) {
		makeAutoObservable(this);
	}

	@action public handleQuestionAnswer = (questionID: number, optionId: number) => {
		const question = find(this.contestQuestions, {id: questionID});
		if (!question || !QuestionUtils.isScheduled(question)) {
			return;
		}

		// if (this.isAuthorized) {
		// 	this._gameplayStore
		// 		.saveAnswers({
		// 			questionId: questionID,
		// 			optionId,
		// 			locale: this.i18n.locale,
		// 		})
		// 		.then(() => {
		// 			question.answers = [
		// 				{
		// 					isCorrect: null,
		// 					questionId: questionID,
		// 					optionId: optionId,
		// 					range: range ? range : null,
		// 				},
		// 			];
		// 		})
		// 		.catch(this.onError);
		// } else {

		question.answer = {
			isCorrect: null,
			questionId: questionID,
			optionId: optionId,
			data: {value: optionId},
		};
	};

	@action updateContestID = async (contestID: number) => {
		await this.requestData(contestID);
		runInAction(() => {
			this._contestID = contestID;
		});
	};

	@action setContestComplete = (contestID: number) => {
		this._gameplayStore.setContestComplete(contestID);
	};

	@action
	private async requestData(contestId: number) {
		this._requestState = RequestState.PENDING;

		try {
			if (isEmpty(this.allContests)) {
				await this._gameplayStore.requestContestsSafety();
			}
			if (contestId) {
				if (this.isAuthorized) {
					await this._gameplayStore.requestData({
						locale: this.i18n.locale,
						contestId: contestId,
					});
				} else {
					this._gameplayStore.setNotAuthQuestionsByContestID(contestId);
				}
			}
			this.onSuccess();
		} catch (e) {
			this.onError(e as AxiosError);
		}
	}

	@action onError = (error: AxiosError) => {
		this._requestState = RequestState.ERROR;
		this._modalsStore.showModal(ModalType.ERROR, {
			message: error.message,
			errors: error.response?.data,
		});
	};

	@action onSuccess = () => {
		this._requestState = RequestState.SUCCESS;
	};

	@action init({contestID}: IControllerProps) {
		this._contestID = contestID;
		this._questionsReactionDisposer = reaction(
			() => this.contestQuestionsWithIntros,
			(questions) => {
				this.stepper = new Stepper(size(questions));
			},
			{fireImmediately: true}
		);
	}

	dispose(): void {
		this._questionsReactionDisposer();
	}

	goToLogin = () => {
		this._modalsStore.showModal(ModalType.LOGIN);
	};

	onCompleteClick = () => {
		const answers: {
			questionId: number;
			data: {value: number | null};
			optionId: number | null;
		}[] = this.contestQuestions.map((question) => {
			return {
				questionId: question.id,
				optionId: question.answer?.optionId || null,
				data: {
					value: question.answer?.data.value || null,
				},
			};
		});
		if (!this.isAuthorized) {
			this.goToLogin();
			Storage.SET("answers", toJS(answers));
			return;
		}

		this._gameplayStore.submitAnswers(toJS(answers)).then().catch(this.onError);

		createConnextraScriptTag(ConnextraType.PICK_CONFIRM, this._userStore?.user);
	};
}
