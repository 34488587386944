import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import type {IUser, IUserStore} from "data/stores/user/user.store";
import {action, makeAutoObservable, observable, runInAction} from "mobx";
import {Bindings} from "data/constants/bindings";
//import type {ChangeEvent, SyntheticEvent} from "react";
import React from "react";

import {RequestState, ModalType} from "data/enums";
import type {AxiosError} from "axios";
import type {IApiResponse} from "data/services/http";
import {isEqual} from "lodash";
import {extractErrorMessage, isBoolean, parseStringToBoolean} from "data/utils";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {ISquadsStore} from "data/stores/squads/squds.store";
import {ISquad} from "../../../data/types/api";
import type {IModalsStore} from "data/stores/modals/modals.store";
import type {IChangePasswordForm} from "data/types/forms";

interface IMyAccountFormElement extends HTMLFormElement {
	username: HTMLInputElement;
	email: HTMLInputElement;
	firstname: HTMLInputElement;
	lastname: HTMLInputElement;
	isNotificationsEnabled: HTMLInputElement;
	favoriteSquadId: HTMLInputElement;
	confirmPassword: HTMLInputElement;
	password: HTMLInputElement;
}

export interface IMyAccountController extends ViewController {
	get isFormDisabled(): boolean;
	get error(): Record<string, string> | null;
	get displayName(): string;
	get favouriteTeamId(): number | null;
	get user(): IUser;
	get isUpdateDisabled(): boolean;
	get squads(): ISquad[];
	get isPasswordUpdateDisabled(): boolean;
	get passwordForm(): IChangePasswordForm;
	onTestPopUp: () => void;
	handleFormSubmit: (event: React.SyntheticEvent<IMyAccountFormElement>) => void;
	handlePasswordSubmit: (event: React.SyntheticEvent<IMyAccountFormElement>) => void;
	handleClearErrorOnChange: () => void;

	handleFormChange: (event: React.ChangeEvent<HTMLFormElement>) => void;

	handleInputDisplayNameValue: (event: React.ChangeEvent<HTMLInputElement>) => void;
	handleInputSquadValue: (id: number) => void;
	handleLogout: () => void;
}

@injectable()
export class MyAccountController implements IMyAccountController {
	@observable private _requestState = RequestState.IDLE;
	@observable private _errorMsg: string | null = null;
	@observable private _errorPlace = "";
	@observable private _displayName = "";
	@observable private _isFormChanged = false;
	@observable private _favoriteSquadId: number | null = null;
	@observable private _passwordForm: IChangePasswordForm = {
		password: "",
		confirmPassword: "",
	};

	get passwordForm(): IChangePasswordForm {
		return this._passwordForm;
	}

	get isUpdateDisabled() {
		return this.isFormDisabled || !this._isFormChanged;
	}

	get user() {
		return this._userStore.user!;
	}

	get squads(): ISquad[] {
		return this._squadsStore.list;
	}

	get displayName() {
		return this._displayName;
	}
	get favouriteTeamId() {
		return this._favoriteSquadId;
	}

	get error() {
		if (!this._errorMsg) return null;

		return {
			[this._errorPlace || "common"]: this._errorMsg,
		};
	}

	get isFormDisabled() {
		return isEqual(this._requestState, RequestState.PENDING);
	}

	get isPasswordUpdateDisabled() {
		const password = this._passwordForm.password;
		const confirmPassword = this._passwordForm.confirmPassword;

		return !password || password !== confirmPassword;
	}

	private fetchSquads(): void {
		void this._squadsStore.fetchSquads();
	}

	init(param: void): void {
		this.fetchSquads();
	}

	constructor(
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.LocalizationStore) private _i18nStore: ILocalizationStore,
		@inject(Bindings.SquadsStore) private _squadsStore: ISquadsStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore
	) {
		makeAutoObservable(this);
		this._displayName = this._userStore.user!.username;
		this._favoriteSquadId = this._userStore.user!.favoriteSquadId;
	}

	@action handleInputDisplayNameValue = (event: React.ChangeEvent<HTMLInputElement>) => {
		this._displayName = event.target.value.replace("@", "");
	};

	@action handleInputSquadValue = (id: number) => {
		this._favoriteSquadId = +id;
		this.handleClearErrorOnChange();
	};

	@action private reportError(error: string, place: string = "") {
		this._errorMsg = error;
		this._errorPlace = place;

		return true;
	}

	@action public handleFormSubmit = (event: React.SyntheticEvent<IMyAccountFormElement>) => {
		event.preventDefault();

		const {username, favoriteSquadId, firstname, lastname, email, isNotificationsEnabled} =
			event.currentTarget;
		const validateList = [
			{field: email, error: "Please provide a valid email address", place: "email"},
			{field: username, error: "Please provide your display name", place: "username"},
		];

		const hasError = validateList.find(({field, error, place}) =>
			field.checkValidity() ? false : this.reportError(error, place)
		);

		if (hasError) {
			return;
		}

		const payload = {
			username: username.value,
			firstName: firstname.value,
			lastName: lastname.value,
			email: email.value,
			isNotificationsEnabled: isNotificationsEnabled.checked,
			favoriteSquadId: +favoriteSquadId.value,
			// lang: this._i18nStore.lang,
		};

		this._requestState = RequestState.PENDING;
		this._userStore
			.update(payload)
			.then(this.onSuccess)
			.then(() =>
				runInAction(() => {
					this._isFormChanged = false;
				})
			)
			.catch(this.onError);
	};

	public handleFormChange = (event: React.ChangeEvent<HTMLFormElement>): void => {
		this.formChangeAction(event.target as unknown as HTMLInputElement);
	};

	@action public handlePasswordSubmit = (event: React.SyntheticEvent<IMyAccountFormElement>) => {
		event.preventDefault();

		const {password, confirmPassword} = event.currentTarget;
		const validateList = [
			{
				field: password,
				error: "Please provide a valid email address",
				place: "confirmPassword",
			},
			{
				field: confirmPassword,
				error: "Please provide your display name",
				place: "confirmPassword",
			},
		];

		const hasError = validateList.find(({field, error, place}) =>
			field.checkValidity() ? false : this.reportError(error, "confirmPassword")
		);

		if (hasError) {
			return;
		}

		const payload = {
			password: password.value,
		};

		this._requestState = RequestState.PENDING;
		this._userStore.update(payload).then(this.onSuccessPassword).catch(this.onError);
	};

	@action private onSuccess = () => {
		this._requestState = RequestState.SUCCESS;
	};

	@action private onSuccessPassword = () => {
		this._requestState = RequestState.SUCCESS;
		this._passwordForm = {password: "", confirmPassword: ""};

		this.onTestPopUp();
	};

	public onTestPopUp = () => {
		this._modalsStore.showModal(ModalType.SUCCESS, {
			title: "Password updated!",
			message: "You have successfully updated your password.",
			buttonText: "Back to my account",
		});
	};

	@action private onError = (error: AxiosError<IApiResponse>) => {
		this._errorMsg = extractErrorMessage(error);
		this._requestState = RequestState.ERROR;
	};

	@action handleClearErrorOnChange = () => {
		this._errorMsg = null;
		this._errorPlace = "";
		this._isFormChanged = true;
	};

	@action handleLogout = () => {
		this._requestState = RequestState.PENDING;
		void this._userStore.logout().then(this.onSuccess).catch(this.onError);
	};

	@action
	private formChangeAction = (target: HTMLInputElement): void => {
		const {name, value, checked, type} = target;
		const keyName = name as keyof IChangePasswordForm;
		const inputValue = type === "checkbox" ? checked : value;

		runInAction(() => {
			this._passwordForm = {
				...this._passwordForm,
				[keyName]: this.parseChangeValue(inputValue),
			};
		});
	};

	private parseChangeValue<T>(value: T): T | boolean {
		if (isBoolean(value)) {
			return parseStringToBoolean(String(value));
		}
		return value;
	}
}
