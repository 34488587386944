/**
 * Wrapper for localStorage that made storage key uniq for any base path.
 * It's required for multiple projects on the same domain but at different folders
 */
import {BASE_URL, STORAGE_VERSION} from "data/constants";

export abstract class Storage {
	/**
	 * Store key/value data in localStorage
	 */
	public static SET(key: string, value: unknown): void {
		const val = typeof value === "string" ? value : JSON.stringify(value || "");
		localStorage.setItem(Storage.generate_key(key), val);
	}

	/**
	 * Get value by key from localStorage
	 */
	public static GET(key: string) {
		return localStorage.getItem(Storage.generate_key(key));
	}

	/**
	 * Remove value by key from localStorage
	 */
	public static REMOVE(key: string): void {
		localStorage.removeItem(Storage.generate_key(key));
	}

	/**
	 * Remove all values from localStorage
	 */
	public static FLUSH(): void {
		localStorage.clear();
	}

	private static generate_key(key: string): string {
		return `${BASE_URL}${key}-${STORAGE_VERSION}`;
	}
}

export default Storage;
