export enum RequestState {
	IDLE,
	PENDING,
	SUCCESS,
	ERROR,
}

export enum SortOrder {
	ASC = "asc",
	DESC = "desc",
}

export enum RoundStatus {
	Scheduled = "scheduled",
	Playing = "playing",
	Complete = "complete",
}

export enum SocialNetwork {
	Facebook,
	Twitter,
	Mobile,
}

export enum ShareType {
	General = "general",
	Contest = "contest",
}

export enum Locale {
	EN_US = "en-US",
}

export enum Language {
	EN = "en",
}

export enum ModalType {
	FORGOT_PASSWORD,
	RESET_PASSWORD,
	ERROR,
	REGISTRATION,
	LOGIN,
	SUCCESS,
}

export enum HelpPagesEnum {
	GUIDELINES = "guidelines",
	TERMS = "terms",
	PRIVACY = "privacy",
	HOW_TO_PLAY = "how_to_play",
	PRIZES = "prizes",
}

export enum ContestStatus {
	ACTIVE = "active",
	COMPLETED = "completed",
	SCHEDULED = "scheduled",
}

export enum QuestionHeaderStatus {
	CORRECT = "correct",
	INCORRECT = "incorrect",
	DISABLED = "disabled",
}
