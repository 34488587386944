import {inject, injectable} from "inversify";
import type {IUser} from "data/stores/user/user.store";
import type {IApiResponse, IHttpClientService} from "data/services/http";
import type {AxiosResponse} from "axios";
import {Bindings} from "data/constants/bindings";

export interface IRegistrationPayload {
	username: string;
	email: string;
	password: string;
	favoriteSquadId: number;
}

export interface IUpdateUserPayload extends Omit<IRegistrationPayload, "password"> {
	isNotificationsEnabled: boolean;
}

export interface IUsername {
	username: string;
}

export type TUserResponse = IApiResponse<{user: IUser}>;

export interface IUserApiProvider {
	register: (params: IRegistrationPayload) => Promise<AxiosResponse<TUserResponse>>;
	update: (params: IUpdateUserPayload) => Promise<AxiosResponse<TUserResponse>>;
	user: () => Promise<AxiosResponse<TUserResponse>>;
	check_username: (params: IUsername) => Promise<AxiosResponse>;
	deactivate_account: () => Promise<AxiosResponse>;
}

@injectable()
export class UserApiProvider implements IUserApiProvider {
	constructor(@inject(Bindings.ApiHTTPClient) private _http: IHttpClientService) {}

	check_username = (params: IUsername) => this._http.post<void>(`user/check_username`, params);

	deactivate_account = () => this._http.post<void>(`user/deactivate_account`);

	register = (params: IRegistrationPayload) =>
		this._http.post<TUserResponse>(`user/register`, params);

	update = (params: IUpdateUserPayload) => this._http.post<TUserResponse>(`user/update`, params);

	user = () => this._http.get<TUserResponse>(`user`);
}
