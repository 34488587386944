import React, {useCallback} from "react";
import {generatePath, useNavigate} from "react-router-dom";
import {
	Box,
	Collapse,
	Divider,
	List,
	ListItemButton,
	Paper,
	Stack,
	Typography,
} from "@mui/material";
import {Exist} from "views/components/exist/exist.component";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import styled from "@emotion/styled";
import {eq, gt, size} from "lodash";
import {ContestDate} from "views/components/contest/contest_date";
import {observer} from "mobx-react";
import {CONTEST_ROUTES} from "data/constants";
import {ContestUtils} from "data/utils/contest_utils";
import {IContest} from "data/types/contests";
import {ContestStatus} from "data/enums";
import {QuestionUtils} from "data/utils/question_utils";

const ContestItemWrapper = styled.div`
	display: flex;
	gap: 2px;
	flex: 1;
	flex-flow: column;
	height: 48px;
	justify-content: center;
	color: var(--primaryColor);
	h6 {
		font-size: 14px;
	}
`;

const ContestItemData = styled.div`
	display: flex;
	gap: 5px;
	align-items: center;
`;

const DropdownHolder = styled(Box)`
	position: relative;
	max-width: 600px;
	width: 100%;
	z-index: 1;
`;

const DropdownPlaceholder = styled(Box)`
	height: 64px;
`;

const DropdownPaper = styled(Paper)`
	position: absolute;
	width: 100%;
	top: 0;
	background: #fff;
	color: var(--primaryColor);
`;

const ListScrollableContainer = styled(Paper)`
	max-height: 200px;
	overflow: auto;
	background: #fff;
	color: var(--primaryColor);
`;

const ListSpacer = styled(Box)`
	height: 10px;
`;

const DropdownWrapper = styled(Stack)`
	position: relative;
	z-index: 6;
	align-items: center;
`;

const ContestStatusItem = styled.div<{color: string; background: string}>`
	height: 24px;

	font-weight: 500;
	font-size: 12px;
	padding: 5px 8px;

	color: ${({color}) => color};
	background: ${({background}) => background};
	border-radius: 51px;
`;

interface IContestItemProps {
	contest: IContest;
	thin?: boolean;
}

const getStatusStyling = (status: ContestStatus, isComplete: boolean) => {
	const statusStyles = {
		scheduled: {
			color: "#2196F3",
			background: "rgba(33, 150, 243, 0.10)",
			copy: "Open",
		},
		active: {
			color: "#18C971",
			background: "rgba(24, 201, 113, 0.1)",
			copy: isComplete ? "Complete" : "LIVE",
		},
		completed: {
			color: "#18C971",
			background: "rgba(24, 201, 113, 0.1)",
			copy: isComplete ? "Complete" : "LIVE",
		},
	};
	// switch (status) {
	// 	case ContestStatus.SCHEDULED:
	// 		return {
	// 			color: "#FF9800",
	// 			background: "rgba(255, 152, 0, 0.1)",
	// 			copy: "Open",
	// 		};
	// 	// case ContestStatus.ACTIVE:
	// 	// 	return {
	// 	// 		color: "#2196F3",
	// 	// 		background: "rgba(33, 150, 243, 0.1)",
	// 	// 		copy: "LIVE",
	// 	// 	};
	// 	default:
	// 		return {
	// 			color: "#18C971",
	// 			background: "rgba(24, 201, 113, 0.1)",
	// 			copy: isComplete ? "Complete" : "LIVE",
	// 		};
	// }
	return statusStyles[status];
};

const ContestDropdownItem: React.FC<IContestItemProps> = observer((props) => {
	const {contest, thin = true} = props;
	const fontWeight = thin ? 400 : 500;
	const fontSize = 16;

	const {color, background, copy} = getStatusStyling(
		contest.status,
		!!(ContestUtils.isComplete(contest) && contest?.questions.every(QuestionUtils.isComplete))
	);

	return (
		<ContestItemWrapper>
			<Typography sx={{fontWeight, fontSize}}>{contest.name}</Typography>
			<ContestItemData>
				{
					<ContestStatusItem color={color} background={background}>
						{copy}
					</ContestStatusItem>
				}
				<ContestDate contest={contest} />
			</ContestItemData>
		</ContestItemWrapper>
	);
});

interface IContestsDropdownProps {
	currentContest?: IContest | null;
	allContests: IContest[];
}

export const ContestsDropdown: React.FC<IContestsDropdownProps> = (props) => {
	const navigate = useNavigate();
	const {currentContest, allContests} = props;
	const restContests = allContests.filter(({id, status}) => !eq(id, currentContest?.id));
	const [isDropdownOpen, setOpenDropdown] = React.useState(false);
	const hasMoreThenOneContest = gt(size(allContests), 1);

	const goToContest = useCallback(
		(contest: IContest) => {
			const [contestGameRoute, contestResultRoute] = CONTEST_ROUTES;
			const routePath = (
				ContestUtils.isScheduled(contest) ? contestGameRoute : contestResultRoute
			).path;
			const path = generatePath(routePath, {id: String(contest.id)});

			navigate(path);
			setOpenDropdown(false);
		},
		[navigate]
	);

	const toggleDropdown = useCallback(() => {
		if (!hasMoreThenOneContest) return;
		setOpenDropdown(!isDropdownOpen);
	}, [isDropdownOpen, hasMoreThenOneContest]);

	if (!currentContest) {
		return <DropdownPlaceholder />;
	}

	return (
		<DropdownWrapper>
			<DropdownHolder>
				<DropdownPlaceholder />
				<DropdownPaper elevation={3}>
					<List
						sx={{width: "100%"}}
						component="nav"
						disablePadding
						aria-labelledby="contests navigation">
						<ListItemButton onClick={toggleDropdown}>
							<ContestDropdownItem contest={currentContest} thin={false} />
							<Exist when={hasMoreThenOneContest}>
								<Exist when={isDropdownOpen}>
									<ExpandLess />
								</Exist>
								<Exist when={!isDropdownOpen}>
									<ExpandMore />
								</Exist>
							</Exist>
						</ListItemButton>
						<Collapse in={isDropdownOpen} timeout="auto" unmountOnExit>
							<Divider />
							<ListScrollableContainer elevation={0}>
								<ListSpacer />
								<List component="div" dense disablePadding>
									{restContests.map((contest) => (
										<ListItemButton
											onClick={() => goToContest(contest)}
											key={contest.id}>
											<ContestDropdownItem contest={contest} />
										</ListItemButton>
									))}
								</List>
								<ListSpacer />
							</ListScrollableContainer>
						</Collapse>
					</List>
				</DropdownPaper>
			</DropdownHolder>
		</DropdownWrapper>
	);
};
