import {isEqual, sumBy} from "lodash";
import {IContest, IQuestion} from "data/types/contests";
import {QuestionUtils} from "data/utils/question_utils";
import {ContestStatus} from "data/enums";

const oneDay = 1000 * 60 * 60 * 24;

export abstract class ContestUtils {
	static status = ContestStatus;

	static isLocksInOneDay = (contest: IContest) => {
		const maxDate = Date.now() + oneDay;
		const startDate = new Date(contest.dateStart).getTime();
		return ContestUtils.isScheduled(contest) && startDate <= maxDate;
	};

	static isLocksAfterOneDay = (contest: IContest) => {
		const maxDate = Date.now() + oneDay;
		const startDate = new Date(contest.dateStart).getTime();
		return ContestUtils.isScheduled(contest) && startDate > maxDate;
	};

	static isLocksInSevenDays = (contest: IContest) => {
		const maxDate = Date.now() + oneDay * 7;
		const startDate = new Date(contest.dateStart).getTime();
		return ContestUtils.isScheduled(contest) && startDate <= maxDate;
	};

	static endsInLessThanOneDay = (contest: IContest) => {
		const curDate = Date.now();
		const endDate = new Date(contest.dateEnd).getTime();
		return ContestUtils.isActive(contest) && endDate - curDate <= oneDay;
	};

	static endsInMoreThanOneDay = (contest: IContest) => {
		const curDate = Date.now();
		const endDate = new Date(contest.dateEnd).getTime();
		return ContestUtils.isActive(contest) && endDate - curDate > oneDay;
	};

	/**
	 * All active and scheduled contests that have a start date of TODAY + 7 days
	 */
	static isCurrent = (contest: IContest) => {
		const {isActive} = ContestUtils;

		const {status, questions} = contest;
		if ([ContestStatus.COMPLETED].includes(status)) {
			return questions.every(QuestionUtils.notComplete);
		}

		return isActive(contest) || ContestUtils.isScheduled(contest);
	};

	static isUpcoming = (contest: IContest) => {
		return ContestUtils.isScheduled(contest);
	};

	static isPast = ({status, questions}: IContest) => {
		if ([ContestStatus.COMPLETED].includes(status)) {
			return questions.every(QuestionUtils.isComplete);
		}
		return false;
	};

	static isActual = ({status}: IContest) =>
		[ContestStatus.SCHEDULED, ContestStatus.ACTIVE].includes(status);

	static isScheduled = ({status}: IContest) => isEqual(ContestStatus.SCHEDULED, status);

	static isActive = ({status}: IContest) => isEqual(ContestStatus.ACTIVE, status);

	static isComplete = ({status}: IContest) => isEqual(ContestStatus.COMPLETED, status);

	static getTotalPoints = (questions?: IQuestion[]) => {
		return sumBy(questions, "cost");
	};
}
