import {inject, injectable} from "inversify";
import type {IUser} from "data/stores/user/user.store";
import type {IApiResponse, IHttpClientService} from "data/services/http";
import type {AxiosResponse} from "axios";
import {Bindings} from "data/constants/bindings";
import {
	ILoginPayload,
	IRegistrationPayload,
	IResetPasswordPayload,
	IUserUpdatePayload,
} from "data/types/api";

type TLoginResponse = IApiResponse<{user: IUser}>;

export interface IAuthApiProvider {
	login: (params: ILoginPayload) => Promise<AxiosResponse<TLoginResponse>>;
	register: (params: IRegistrationPayload) => Promise<AxiosResponse<TLoginResponse>>;
	update: (params: IUserUpdatePayload) => Promise<AxiosResponse<TLoginResponse>>;
	logout: () => Promise<AxiosResponse>;
	fetchUser: () => Promise<AxiosResponse<TLoginResponse>>;
	forgotPasswordRequest: (params: string) => Promise<AxiosResponse<unknown>>;
	resetPasswordRequest: (params: IResetPasswordPayload) => Promise<AxiosResponse<unknown>>;
}

@injectable()
export class AuthApiProvider implements IAuthApiProvider {
	constructor(@inject(Bindings.ApiHTTPClient) private _http: IHttpClientService) {}

	fetchUser = () => this._http.get<TLoginResponse>("user");

	login = (params: ILoginPayload) => this._http.post<TLoginResponse>("auth/login", params);

	register = (params: IRegistrationPayload) =>
		this._http.post<TLoginResponse>("user/register", params);

	update = (params: IUserUpdatePayload) => this._http.post<TLoginResponse>("user/update", params);

	logout = () => this._http.post("auth/logout");

	forgotPasswordRequest(params: string): Promise<AxiosResponse<unknown>> {
		return this._http.post<unknown>("auth/password_reset/request", {
			email: params,
		});
	}

	resetPasswordRequest(params: IResetPasswordPayload): Promise<AxiosResponse<unknown>> {
		return this._http.post<unknown>("auth/password_reset", params);
	}
}
