import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {action, computed, makeAutoObservable} from "mobx";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {IUser, IUserStore} from "data/stores/user/user.store";

import {QuestionUtils} from "data/utils/question_utils";
import {Bindings} from "data/constants/bindings";
import {IQuestionWithAnswers} from "data/types/contests";
import {QuestionHeaderStatus} from "data/enums";

interface IControllerProps {
	question: IQuestionWithAnswers;
}

type TQuestionStringProps = {
	status?: QuestionHeaderStatus;
	statusMessages: string[];
	points?: string;
	optionsAmount?: number;
	isImageOptions?: boolean;
};

export interface IContestQuestionCardController extends ViewController<IControllerProps> {
	get i18n(): ILocalizationStore;
	get questionStringProps(): TQuestionStringProps;
	updateQuestion: (question: IControllerProps["question"]) => void;
	onSliderUpdate: (value: number, emit?: boolean) => void;
	get user(): IUser;
}

@injectable()
export class ContestQuestionCardController implements IContestQuestionCardController {
	question?: IControllerProps["question"];

	@computed get questionStringProps() {
		const question = this.question;
		const options = this.question?.options;

		if (!question)
			return {
				statusMessages: [],
			};

		const i18n = this.i18n;
		const msgLocked = i18n.t("question.header.locked.message", "Locked");
		const msgCongratulations = i18n.t(
			"question.header.complete.congratulations",
			"Congratulations!"
		);
		const msgIncorrect = i18n.t("question.header.complete.wrong_answer", "Nice try!");
		const msgNoAnswer = i18n.t(
			"question.header.complete.no_answer",
			"Oops, you missed this one!"
		);
		const msgNoPoints = i18n.t("question.header.complete.no_pts", "You don’t receive points.");
		const msgWonPts = i18n.t("question.header.complete.won_pts", "You've won {{ X }}pts.", {
			X: QuestionUtils.getPointsValue(question),
		});

		const ptsCopy = i18n.t("question.footer.pts", "pts");

		let status,
			statusMessages: string[] = [];

		const isImageOptions: boolean = QuestionUtils.hasImageInOptions(question);

		if (QuestionUtils.isLocked(question)) {
			status = QuestionHeaderStatus.DISABLED;
			statusMessages = [msgLocked];
		}

		if (QuestionUtils.isComplete(question)) {
			if (QuestionUtils.hasCorrectAnswer(question)) {
				status = QuestionHeaderStatus.CORRECT;
				statusMessages = [msgCongratulations, msgWonPts];
			} else {
				const msgIncorrectVariant = QuestionUtils.hasIncorrectAnswer(question)
					? msgIncorrect
					: msgNoAnswer;

				status = QuestionHeaderStatus.INCORRECT;
				statusMessages = [msgIncorrectVariant, msgNoPoints];
			}
		}

		return {
			status,
			statusMessages,
			points: `${QuestionUtils.getPointsValue(question)} ${ptsCopy}`,
			optionsAmount: options?.length,
			isImageOptions: isImageOptions,
		};
	}

	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.UserStore) private _userStore: IUserStore
	) {
		makeAutoObservable(this);
	}

	@action updateQuestion = (question: IControllerProps["question"]) => {
		this.question = question;
	};
	@action
	public onSliderUpdate = (value: number): void => {
		if (!this.question) {
			return;
		}
		this.question.answer = {
			isCorrect: null,
			questionId: this.question.id,
			optionId: this.question.range?.id,
			data: {value},
		};
	};

	init({question}: IControllerProps) {
		this.updateQuestion(question);
	}
	get user() {
		return this._userStore.user!;
	}
	dispose(): void {
		return;
	}
}
