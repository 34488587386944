import React, {useCallback} from "react";
import {observer} from "mobx-react";
import {FormRegistration} from "views/components/FormRegistration/formRegistration.component";
import {Modal} from "@mui/material";
import {ModalCloseButton, ModalContent} from "views/components/modals/common";
import {useViewController} from "data/services/locator";
import CloseIcon from "@mui/icons-material/Close";
import {IModalRegistrationController} from "views/components/modals/ModalRegistration/modalRegistration.controller";
import {Bindings} from "data/constants/bindings";
import logo from "assets/img/login_logo.png";
import styled from "@emotion/styled";

const Logo = styled.img`
	display: block;

	@media (max-width: 860px) {
		margin: 24px auto 20px auto;
	}
`;

const RegFormTitle = styled.h4`
	margin-top: 12px;
`;
const StyledModalContent = styled(ModalContent)`
	padding: 0;
	overflow: hidden;
`;
const StyledModalCloseButton = styled(ModalCloseButton)`
	position: sticky;
	align-self: flex-end;
`;
export const ModalRegistration: React.FC = observer(() => {
	const {isOpen, close} = useViewController<IModalRegistrationController>(
		Bindings.ModalRegistrationController
	);

	const handleClose = useCallback(
		(event: unknown, reason?: "backdropClick" | "escapeKeyDown") => {
			if (reason === "backdropClick") {
				return;
			}

			close();
		},
		[close]
	);

	return (
		<Modal className="full-screen" open={isOpen} onClose={handleClose}>
			<StyledModalContent>
				<StyledModalCloseButton onClick={close}>
					<CloseIcon />
				</StyledModalCloseButton>
				<Logo src={logo} role="banner" alt="" />
				<RegFormTitle>Register an account</RegFormTitle>
				<FormRegistration />
			</StyledModalContent>
		</Modal>
	);
});
