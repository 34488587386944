import {ViewController} from "data/types/structure";
import React from "react";
import {inject, injectable} from "inversify";
import {action, makeAutoObservable, observable, runInAction} from "mobx";
import {Bindings} from "data/constants/bindings";
import type {ICountriesStore} from "data/stores/countries/countries.store";
import type {ISquadsStore} from "data/stores/squads/squds.store";
import {PasswordValidator} from "data/utils/helpers/validators/PasswordValidator";
import {EmailValidator} from "data/utils/helpers/validators/Email.validator";
import {UsernameValidator} from "data/utils/helpers/validators/Username.validator";

import type {IRegisterForm} from "data/types/forms";
import {ValidationScheme} from "data/types/validators";
import type {IFormValidator} from "data/utils/helpers/validators/FormValidator";

import type {IUserStore} from "data/stores/user/user.store";
import type {IModalsStore} from "data/stores/modals/modals.store";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {IFormValidationHelper} from "data/utils/form_validation_helper";
import {Storage} from "data/utils/Storage";
import type {IGameplayStore} from "data/stores/gameplay/gameplay.store";

import {Empty} from "data/types/common";
import {IRegistrationPayload, ISquad} from "data/types/api";
import {AxiosError} from "axios";
import {ModalType} from "data/enums";
import {parseStringToBoolean, isBoolean} from "data/utils";
import {ConnextraType, createConnextraScriptTag} from "data/utils/connextra";

export interface IFormRegistrationController extends ViewController {
	handleFormChange: (event: React.ChangeEvent<HTMLFormElement>) => void;

	handleSelectChange: (event: React.ChangeEvent<HTMLInputElement>) => void;

	handleFormSubmit: (event: React.SyntheticEvent<HTMLFormElement>) => void;

	togglePasswordVisibility: () => void;

	toggleConfirmPasswordVisibility: () => void;

	clearFormError: () => void;

	goToLogin: () => void;

	onSuccess: () => void;

	get i18n(): ILocalizationStore;

	get isPasswordVisible(): boolean;

	get isConfirmPasswordVisible(): boolean;

	get form(): IRegisterForm;

	get squads(): ISquad[];

	get isLoading(): boolean;

	get error(): string | undefined;

	get formErrors(): Record<string, Empty<string>>;
}

@injectable()
export class FormRegistrationController implements IFormRegistrationController {
	private readonly _validationScheme: ValidationScheme = {
		password: [new PasswordValidator()],
		email: [new EmailValidator()],
		username: [new UsernameValidator()],
	};
	@observable private _error?: string;

	constructor(
		@inject(Bindings.LocalizationStore) public i18n: ILocalizationStore,
		@inject(Bindings.CountriesStore) private _countriesStore: ICountriesStore,
		@inject(Bindings.SquadsStore) private _squadsStore: ISquadsStore,
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore,
		@inject(Bindings.FormValidator) private _formValidator: IFormValidator,
		@inject(Bindings.GameplayStore) private readonly _gameplayStore: IGameplayStore,

		@inject(Bindings.FormValidationHelper) private _validationHelper: IFormValidationHelper
	) {
		this._formValidator.enterScheme(this._validationScheme);
		makeAutoObservable(this);
	}

	get error(): string | undefined {
		return this._error;
	}

	@observable private _isPasswordVisible: boolean = false;

	get isPasswordVisible(): boolean {
		return this._isPasswordVisible;
	}

	@observable private _isConfirmPasswordVisible: boolean = false;

	get isConfirmPasswordVisible(): boolean {
		return this._isConfirmPasswordVisible;
	}

	@observable private _isLoading: boolean = false;

	get isLoading(): boolean {
		return this._isLoading;
	}

	@observable private _form: IRegisterForm = {
		email: "",
		firstName: "",
		lastName: "",
		password: "",
		confirmPassword: "",
		username: "",
		favoriteSquadId: "",
		marketingOptin: true,
		mobile: null,
		ageOptin: false,
	};

	get form(): IRegisterForm {
		return this._form;
	}

	get formErrors(): Record<string, Empty<string>> {
		return this._formValidator.formErrors;
	}

	get squads(): ISquad[] {
		return this._squadsStore.list;
	}

	public handleFormChange = (event: React.ChangeEvent<HTMLFormElement>): void => {
		this.formChangeAction(event.target as unknown as HTMLInputElement);
	};

	handleSelectChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
		this.formChangeAction(event.target);
	};

	private handleConnexTagging = (form: IRegisterForm): void => {
		if (form.marketingOptin) {
			createConnextraScriptTag(ConnextraType.OPTIN);
		}

		createConnextraScriptTag(ConnextraType.REGISTRATION_CONFIRM);
	};

	@action
	public handleFormSubmit = (event: React.SyntheticEvent<HTMLFormElement>): void => {
		event.preventDefault();
		event.stopPropagation();

		this._formValidator.clearErrors();
		if (!this.form.favoriteSquadId || this.form.favoriteSquadId === 0) {
			this._formValidator.setError("favoriteSquadId", "Please select your favorite team");
			return;
		}

		const isValid = this._formValidator.validate(event.currentTarget);

		if (!isValid) {
			return;
		}

		if (this.form.password !== this.form.confirmPassword) {
			return;
		}

		const favSquadToBack =
			this.form.favoriteSquadId === 999999 ? null : this.form.favoriteSquadId;

		const payload: IRegistrationPayload = {
			email: this.form.email,
			firstName: this.form.firstName,
			lastName: this.form.lastName,
			favoriteSquadId: favSquadToBack,
			marketingOptin: this.form.marketingOptin,
			password: this.form.password,
			username: this.form.username,
			mobile: this.form.mobile ? this.form.mobile.replace(/\D/g, "") : this.form.mobile,
			ageOptin: this.form.ageOptin,
		};
		this.changeLoadingState(true);

		this._userStore
			.register(payload)
			.then(this.onSuccess)
			.catch((error: AxiosError<{errors: {message: string}[]}>) => {
				this._error = error.response?.data?.errors[0]?.message || error.message;
			})
			.finally(() => this.changeLoadingState(false));

		this.handleConnexTagging(this.form);
	};

	@action
	public toggleConfirmPasswordVisibility = (): void => {
		runInAction(() => {
			this._isConfirmPasswordVisible = !this._isConfirmPasswordVisible;
		});
	};

	@action
	public togglePasswordVisibility = (): void => {
		runInAction(() => {
			this._isPasswordVisible = !this._isPasswordVisible;
		});
	};

	@action
	public clearFormError = () => {
		runInAction(() => {
			this._error = undefined;
		});
	};

	public goToLogin = (): void => {
		this._modalsStore.showModal(ModalType.LOGIN);
	};

	@action onSuccess = () => {
		const savedStorageAnswers = Storage.GET("answers");
		const savedAnswers = savedStorageAnswers
			? (JSON.parse(savedStorageAnswers) as {
					questionId: number;
					data: {value: number | null};
					optionId: number | null;
			  }[])
			: [];
		if (savedAnswers) {
			void this._gameplayStore.submitAnswers(savedAnswers);
			Storage.FLUSH();
			this._modalsStore.hideModal();
			return;
		}

		this._modalsStore.hideModal();
	};
	init(param: void): void {
		this.fetchSquads();
	}

	dispose(): void {
		return;
	}

	@action
	private formChangeAction(target: HTMLInputElement) {
		const {name, value, checked, type} = target;

		const inputValue = type === "checkbox" ? checked : value;
		if (!name) {
			return;
		}
		const keyName = name as keyof IRegisterForm;
		runInAction(() => {
			this._form = {
				...this._form,
				[keyName]: this.parseChangeValue(inputValue),
			};
			if (keyName !== "mobile") {
				this.setCursor(target);
			}
		});
		this.clearFormError();
	}

	private fetchSquads(): void {
		void this._squadsStore.fetchSquads();
	}

	private setCursor(target: HTMLInputElement): void {
		if (target.type === "checkbox" || !target.type) {
			return;
		}

		const cursor = Number(target.selectionStart);
		setTimeout(
			() => {
				target.setSelectionRange(cursor, cursor);
			},
			0,
			1
		);
	}

	private parseChangeValue<T>(value: T): T | boolean {
		if (isBoolean(value)) {
			return parseStringToBoolean(String(value));
		}
		return value;
	}

	private changeLoadingState(value: boolean): void {
		runInAction(() => {
			this._isLoading = value;
		});
	}
}
