import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {IHttpClientService} from "data/services/http";
import {AxiosResponse} from "axios";
import {IApiResponse} from "data/types/common";
import {IAnswer, IAnsweredContest} from "data/types/contests";

export interface ISaveRequests {
	variables: {locale: string; questionId: number; optionId: number};
	response: IApiResponse<{questionId: number; optionId: number}>;
}

export interface ISubmitRequests {
	variables: {questionId: number; data: {value: number | null}; optionId: number | null}[];
	response: IApiResponse<{questionId: number; optionId: number}>;
}

export interface IGetRequests {
	variables: {locale: string; contestId: number};
	response: IApiResponse<{
		answers: IAnswer[];
	}>;
}

export interface IGetSubmittedRequests {
	variables: {locale: string};
	response: IAnsweredContest[];
}

export interface IGameplayApiProvider {
	saveAnswers: (
		variables: ISaveRequests["variables"]
	) => Promise<AxiosResponse<ISaveRequests["response"]>>;
	getAnswers: (
		variables: IGetRequests["variables"]
	) => Promise<AxiosResponse<IGetRequests["response"]>>;

	getAnsweredContests: () => Promise<AxiosResponse<IGetSubmittedRequests["response"]>>;

	submitAnswers: (
		variables: {questionId: number; data: {value: number | null}; optionId: number | null}[]
	) => Promise<AxiosResponse<ISaveRequests["response"]>>;
}

@injectable()
export class GameplayAPIProvider implements IGameplayApiProvider {
	constructor(@inject(Bindings.ApiHTTPClient) private _http: IHttpClientService) {}

	saveAnswers = ({locale, ...params}: ISaveRequests["variables"]) =>
		this._http.post<ISaveRequests["response"]>(`answer/${params.questionId}`, {
			optionId: params.optionId,
		});

	getAnswers = ({contestId}: IGetRequests["variables"]) =>
		this._http.get<IGetRequests["response"]>(`pickem/answer/${contestId}`);

	submitAnswers = ({
		...params
	}: ISubmitRequests["variables"]): Promise<AxiosResponse<ISubmitRequests["response"]>> =>
		this._http.post<ISubmitRequests["response"]>(`pickem/answers`, params);

	getAnsweredContests = () =>
		this._http.get<IGetSubmittedRequests["response"]>(`pickem/answers/submit`);
}
