import {action, computed, makeAutoObservable, observable, runInAction} from "mobx";
import {inject, injectable} from "inversify";
import type {IAuthApiProvider} from "data/providers/api/auth.api.provider";
import {Bindings} from "data/constants/bindings";
import type {
	ILoginPayload,
	IRegistrationPayload,
	IResetPasswordPayload,
	IUserUpdatePayload,
} from "data/types/api";
import {DEFAULT_NO_TEAM_KEY} from "data/constants";

export interface IUser {
	id: number;
	email: string;
	username: string;
	firstName: string;
	lastName: string;
	country: string;
	isNotificationsEnabled: boolean;
	favoriteSquadId: number | null;
	isTutorialViewed: boolean;
	postseasonModal: boolean;
}

export interface IUserStore {
	get user(): IUser | undefined;

	get fullName(): string;

	get isAuthorized(): boolean;

	get wasLoggedOut(): boolean;

	get isRequestingUser(): boolean;

	get userHasFavouriteTeam(): boolean;

	login(payload: ILoginPayload): Promise<void>;

	register(payload: IRegistrationPayload): Promise<void>;

	update(payload: IUserUpdatePayload): Promise<void>;

	logout(): Promise<void>;

	fetchUser(): Promise<void>;

	forgotPasswordRequest(email: string): Promise<unknown>;

	resetPasswordRequest(payload: IResetPasswordPayload): Promise<unknown>;

	setTutorialViewed(): Promise<void>;
}

@injectable()
export class UserStore implements IUserStore {
	constructor(@inject(Bindings.AuthApiProvider) private _authApi: IAuthApiProvider) {
		makeAutoObservable(this);
	}

	get userHasFavouriteTeam(): boolean {
		if (!this.user?.favoriteSquadId) {
			return false;
		}
		return this.user.favoriteSquadId !== DEFAULT_NO_TEAM_KEY;
	}

	@observable private _isRequestingUser: boolean = true;

	get isRequestingUser(): boolean {
		return this._isRequestingUser;
	}

	@observable private _user?: IUser = undefined;

	get user() {
		return this._user;
	}

	@observable private _isAuthorized = false;

	get isAuthorized(): boolean {
		return this._isAuthorized;
	}

	@observable private _wasLoggedOut = false;

	get wasLoggedOut(): boolean {
		return this._wasLoggedOut;
	}

	@computed get fullName() {
		if (!this._user) {
			return "";
		}

		const {firstName, lastName} = this._user;
		return `${firstName} ${lastName}`;
	}

	@action
	async login(payload: ILoginPayload): Promise<void> {
		const response = await this._authApi.login(payload);
		const {user} = response.data.success;

		runInAction(() => {
			this._user = user;
			this._isAuthorized = true;
			this._wasLoggedOut = false;
		});
	}

	@action
	public async register(payload: IRegistrationPayload): Promise<void> {
		const response = await this._authApi.register(payload);
		const {user} = response.data.success;

		runInAction(() => {
			this._user = user;
			this._isAuthorized = true;
			this._wasLoggedOut = false;
		});
	}

	@action
	public async update(payload: IUserUpdatePayload): Promise<void> {
		const response = await this._authApi.update(payload);
		const {user} = response.data.success;

		runInAction(() => {
			this._user = user;
			this._isAuthorized = true;
			this._wasLoggedOut = false;
		});
	}

	@action
	public async fetchUser(): Promise<void> {
		try {
			this.changeLoadingState(true);
			const response = await this._authApi.fetchUser();
			const {user} = response.data.success;

			runInAction(() => {
				this._user = user;
				this._isAuthorized = true;
				this._wasLoggedOut = false;
			});
		} finally {
			this.changeLoadingState(false);
		}
	}

	@action
	async logout() {
		await this._authApi.logout();

		runInAction(() => {
			this._user = undefined;
			this._isAuthorized = false;
			this._wasLoggedOut = true;
		});

		window.location.href = "/";
	}

	forgotPasswordRequest(email: string): Promise<unknown> {
		return this._authApi.forgotPasswordRequest(email);
	}

	resetPasswordRequest(payload: IResetPasswordPayload): Promise<unknown> {
		return this._authApi.resetPasswordRequest(payload);
	}

	@action
	private changeLoadingState(value: boolean): void {
		runInAction(() => {
			this._isRequestingUser = value;
		});
	}

	public async setTutorialViewed(): Promise<void> {
		try {
			await this.update({isTutorialViewed: true});
			return Promise.resolve();
		} catch (e) {
			return Promise.reject(e);
		}
	}
}
