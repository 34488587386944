import {ViewController} from "data/types/structure";
import type {ILoginPayload} from "data/types/api";
import {inject, injectable} from "inversify";
import {action, makeAutoObservable, observable, runInAction} from "mobx";
import React from "react";
import {ModalType, RequestState} from "data/enums";
import {Bindings} from "data/constants/bindings";
import type {IUserStore} from "data/stores/user/user.store";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {noop} from "lodash";
import {AxiosError} from "axios";
import {Storage} from "data/utils/Storage";
import type {IGameplayStore} from "data/stores/gameplay/gameplay.store";
// import {useNavigate} from "react-router-dom";

interface ILoginForm extends HTMLFormElement {
	email: HTMLInputElement;
	password: HTMLInputElement;
}

export interface IFormLoginController extends ViewController {
	handleLoginForm: (event: React.SyntheticEvent<ILoginForm>) => void;
	login: (params: ILoginPayload) => Promise<void>;
	resetFormErrors: () => void;
	togglePasswordVisibility: () => void;
	openForgotPasswordModal: () => void;
	goToRegister: () => void;
	onSuccess: () => void;
	error?: string;

	get isPasswordVisible(): boolean;

	get isLoading(): boolean;
}

@injectable()
export class FormLoginController implements IFormLoginController {
	@observable _requestState: RequestState = RequestState.IDLE;

	constructor(
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore,
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.GameplayStore) private readonly _gameplayStore: IGameplayStore
	) {
		makeAutoObservable(this);
	}

	@observable private _isPasswordVisible: boolean = false;

	get isPasswordVisible(): boolean {
		return this._isPasswordVisible;
	}

	@observable _error?: string = undefined;

	get error() {
		return this._error;
	}

	get isLoading() {
		return this._requestState === RequestState.PENDING;
	}

	// private get isParlayLocked(): boolean {
	// 	return this.votedProps.some((e) => e.status !== PropStatus.Open);
	// }

	@action resetFormErrors = () => {
		this._error = undefined;
		this._requestState = RequestState.IDLE;
	};

	@action login({email, password}: ILoginPayload) {
		this._requestState = RequestState.PENDING;
		return this._userStore
			.login({email, password})
			.then(this.onSuccess)
			.catch((e: AxiosError<{errors: {message: string}[]}>) => {
				this.onLoginError(e);
				return Promise.reject(e);
			});
	}

	@action handleLoginForm = (event: React.SyntheticEvent<ILoginForm>) => {
		event.preventDefault();
		const {email, password} = event.currentTarget;

		this.login({
			email: email.value,
			password: password.value,
		}).catch(noop);
	};
	@action onSuccess = () => {
		const savedAnswersString = Storage.GET("answers");
		const savedAnswers = savedAnswersString
			? (JSON.parse(savedAnswersString) as {
					questionId: number;
					data: {value: number | null};
					optionId: number | null;
			  }[])
			: [];
		if (savedAnswers) {
			void this._gameplayStore.submitAnswers(savedAnswers);
			Storage.FLUSH();
			this._modalsStore.hideModal();
			return;
		}

		this._modalsStore.hideModal();
	};

	public openForgotPasswordModal = () => {
		this._modalsStore.showModal(ModalType.FORGOT_PASSWORD);
	};

	public goToRegister = () => {
		this._modalsStore.showModal(ModalType.REGISTRATION);
	};

	@action
	public togglePasswordVisibility = () => {
		runInAction(() => {
			this._isPasswordVisible = !this._isPasswordVisible;
		});
	};

	dispose(): void {
		return;
	}

	init(param: void): void {
		return;
	}

	@action private onLoginError = (error: AxiosError<{errors: {message: string}[]}>) => {
		this._error = error.response?.data?.errors[0]?.message || error.message;
		this._requestState = RequestState.ERROR;
	};
}
