import React from "react";
import {useViewController} from "data/services/locator";
import {IModalsController} from "views/components/modals/modals.controller";
import {Bindings} from "data/constants/bindings";
import {IModalMessage} from "data/types/modals";
import {Modal, Button} from "@mui/material";
import {
	ModalCloseButton,
	ModalContent,
	ModalText,
	ModalTitle,
} from "views/components/modals/common";
import {Exist} from "views/components/exist/exist.component";
import {ReactComponent as IconClose} from "assets/img/icons/close.svg";
import {ReactComponent as IconSuccess} from "assets/img/icons/success_rounded.svg";
import {observer} from "mobx-react";
import {ModalType} from "data/enums";

export const ModalSuccess: React.FC = observer(() => {
	const {isModalOpen, i18n, close, modalContent} = useViewController<IModalsController>(
		Bindings.ModalsController
	);
	const isOpen = isModalOpen(ModalType.SUCCESS);

	const data = modalContent as IModalMessage;
	return (
		<Modal open={isOpen} onClose={close}>
			<ModalContent>
				<ModalCloseButton sx={{color: "black"}} onClick={close}>
					<IconClose />
				</ModalCloseButton>
				<IconSuccess />

				<Exist when={Boolean(data?.title)}>
					<ModalTitle>{i18n.t(data?.title ?? "success")}</ModalTitle>
				</Exist>
				<ModalText>{data?.message ?? "-"}</ModalText>

				<Button
					sx={{minWidth: {xs: "100%", md: 180}, ml: "auto", mr: 0, marginLeft: "initial"}}
					variant="contained"
					onClick={close}>
					{i18n.t(data?.buttonText || "modal.general.close")}
				</Button>
			</ModalContent>
		</Modal>
	);
});
