import {RequestState, ModalType} from "data/enums";
import type {IGameplayStore} from "data/stores/gameplay/gameplay.store";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {IModalsStore} from "data/stores/modals/modals.store";
import type {IStandingsStore} from "data/stores/standings/standings.store";
import type {IUserStore} from "data/stores/user/user.store";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {isEqual, defaultTo} from "lodash";
import {action, makeAutoObservable, observable, runInAction} from "mobx";
import {Bindings} from "data/constants/bindings";
// import {ContestUtils} from "data/utils/contest_utils";
// import {trackSentryErrors} from "data/utils";
import {IContest} from "data/types/contests";
import {IRankings, IRankUser} from "data/types/standings";
import {AxiosError} from "axios";

interface IControllerProps {
	leagueId: number | null;
	contestID?: number;
}

export interface IStandingsController extends ViewController<IControllerProps> {
	get i18n(): ILocalizationStore;

	get requestState(): RequestState;

	get selectedContestId(): number | null;

	get ladder(): IRankings;

	get isLoadMore(): boolean;

	get isLoading(): boolean;

	get gameContests(): IContest[];

	get isPreSeasonState(): boolean;

	get isLoadingLadder(): boolean;

	get isLadderEmpty(): boolean;

	get isOverallPoints(): boolean;

	get userRank(): IRankUser | null;

	get pageSize(): number;

	get userStoreId(): number | undefined;

	get canShowOwnUserRow(): boolean;

	isUserInCurrentList(): boolean;

	isOwnUser(userId?: number): boolean;

	setContestId(contestId: number): Promise<void>;

	increasePageNumber(): Promise<void>;

	getRankData(user?: IRankUser | null): {points: number; rank: number};
}

@injectable()
export class StandingsController implements IStandingsController {
	@observable private _leagueId: number | null = null;
	@observable private _contestId: number | null = null;
	@observable private _requestState: RequestState = RequestState.IDLE;
	@observable private _requestLadderState: RequestState = RequestState.IDLE;
	@observable private _pageNumber = 1;
	private _pageSize = 20;

	get requestState() {
		return this._requestState;
	}

	get pageSize() {
		return this._pageSize;
	}

	get isLoading() {
		return isEqual(this._requestState, RequestState.PENDING);
	}

	get isLoadingLadder() {
		return [RequestState.PENDING, RequestState.IDLE].includes(this._requestLadderState);
	}

	get isOverallPoints() {
		return !this._contestId;
	}

	get isLadderEmpty() {
		return !this.ladder?.rankings.length && !this.isLoadingLadder && !this.isPreSeasonState;
	}

	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.StandingsStore) public readonly _standingsStore: IStandingsStore,
		@inject(Bindings.ModalsStore) public readonly _modalsStore: IModalsStore,
		@inject(Bindings.UserStore) public readonly _userStore: IUserStore,
		@inject(Bindings.GameplayStore) public readonly _gamePlayStore: IGameplayStore
	) {
		makeAutoObservable(this);
	}

	@action async setContestId(contestId: number) {
		if (this._contestId !== contestId) {
			this._standingsStore.clear();
			this._pageNumber = 1;
			this._contestId = contestId;
			await this._fetchLadder(this._contestId);
		}
	}

	get ladder(): IRankings {
		// if (!this._leagueHasRanks) {
		// 	return this._buildLadderFromUsers;
		// }

		return this._standingsStore.ladder;
	}

	isUserInCurrentList() {
		if (!this.ladder.rankings.length) {
			return false;
		}
		return Boolean(this.ladder.rankings.find((rank) => this.isOwnUser(rank.userId)));
	}

	get userRank(): IRankUser | null {
		if (this.isUserInCurrentList()) {
			return this.ladder.rankings.find((rank) => this.isOwnUser(rank.userId)) || null;
		}

		return this._standingsStore.userRank;
	}

	get gameContests(): IContest[] {
		return [...this._gamePlayStore.pastContests, ...this._gamePlayStore.activeContests];
	}

	//  get leagueContests() {
	//  	const contests = this.gameContests;
	// // 	const league = this.league;
	// //
	// // 	if (league && league.startId) {
	// // 		return contests.filter((contest) => gte(contest.id, league.startId));
	// // 	}
	// //
	// 	return contests;
	//  }

	get isPreSeasonState(): boolean {
		return false;
	}

	get userStoreId(): number | undefined {
		return this._userStore.user?.id;
	}

	get selectedContestId(): number | null {
		return this._contestId;
	}

	get isLoadMore(): boolean {
		return !!this.ladder?.nextPage;
	}

	get canShowOwnUserRow(): boolean {
		return Boolean(!this.isUserInCurrentList() && this.userRank);
	}

	private get _defaultContestId() {
		return 0;
	}

	private get _leagueHasRanks() {
		return false;
	}

	// private get _buildLadderFromUsers(): IRankings {
	// 	return {};
	// 	// return {
	// 	// 	nextPage: this._leaguesStore.leagueUsers.nextPage,
	// 	// 	user: null,
	// 	// 	rankings: this._leaguesStore.leagueUsers.users.map((user) => {
	// 	// 		const userRank: IRankUser = {
	// 	// 			userId: user.userId,
	// 	// 			rank: 0,
	// 	// 			overallRank: 0,
	// 	// 			points: 0,
	// 	// 			overallPoints: 0,
	// 	// 			userName: user.userName || "-",
	// 	// 			leagueId: null,
	// 	// 			contestId: null,
	// 	// 		};
	// 	//
	// 	// 		return userRank;
	// 	// 	}),
	// 	// };
	// }

	private get _isUsersModeEnabled() {
		return this._leagueId !== null && !this._leagueHasRanks;
	}

	isOwnUser(userId?: number): boolean {
		return Number(this.userStoreId) === Number(userId);
	}

	@action async increasePageNumber() {
		this._pageNumber = this._pageNumber + 1;

		if (this._contestId != null) {
			await this._fetchLadder(this._contestId);
		}
	}

	@action dispose(): void {
		this._standingsStore.clear();
		this._contestId = this._defaultContestId;
		this._pageNumber = 1;
	}

	@action
	async init({contestID}: IControllerProps) {
		try {
			this._requestState = RequestState.PENDING;

			await this._gamePlayStore.requestContestsSafety();

			runInAction(() => {
				this._requestState = RequestState.SUCCESS;
			});

			runInAction(() => {
				this._contestId = contestID ?? this._defaultContestId;
			});

			if (this._contestId !== null) {
				await this._fetchLadder(this._contestId);
			}
		} catch (err) {
			// trackSentryErrors(err, {}, "fetch standings");
			// const error = err as AxiosError;
			// this._modalsStore.showModal(ModalType.ERROR, {
			// 	message: error.message,
			// 	errors: error.response?.data,
			// });
		}
	}

	@action
	async _fetchLadder(contestId: number | undefined) {
		this._requestLadderState = RequestState.PENDING;

		try {
			if (this._isUsersModeEnabled) {
				console.log(98);
				// await this._fetchUsers(leagueId!, newPage);
			} else {
				await this._standingsStore.getLadder({
					contest: contestId === 0 ? null : contestId,
					limit: this._pageSize,
					page: this._pageNumber,
					locale: "en",
				});
			}

			runInAction(() => {
				this._requestLadderState = RequestState.SUCCESS;
			});
		} catch (err) {
			// trackSentryErrors(err, {}, "standings fetch ladder");
			this._onCatchError(err);
		}
	}

	// @action
	// async _fetchUsers(leagueId: number, newPage: boolean = false) {
	// 	await this._leaguesStore.fetchLeagueUsers(
	// 		leagueId,
	// 		this._pageSize,
	// 		this._pageNumber,
	// 		"en",
	// 		newPage
	// 	);
	// }

	@action _onCatchError(err: unknown) {
		// trackSentryErrors(err, {}, "standings error handler");
		this._requestLadderState = RequestState.ERROR;

		const error = err as AxiosError;
		this._modalsStore.showModal(ModalType.ERROR, {
			message: error.message,
			errors: error.response?.data,
		});
	}

	getRankData(user?: IRankUser | null) {
		if (!user) {
			return {
				points: 0,
				rank: 0,
			};
		}

		if (this.isOverallPoints) {
			return {
				points: defaultTo(user.overallPoints, 0),
				rank: defaultTo(user.overallRank, 0),
			};
		}

		return {
			points: defaultTo(user.points, 0),
			rank: defaultTo(user.rank, 0),
		};
	}
}
