/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable @typescript-eslint/no-unused-vars */

/*
 * Disabled the following rules:
 * @typescript-eslint/naming-convention
 * @typescript-eslint/no-empty-interface
 * @typescript-eslint/no-unused-vars
 * as MUI theme override required override interfaces
 */
import {PaletteColorOptions, ThemeOptions} from "@mui/material/styles";

declare module "@mui/material/styles" {
	interface CustomPalette {
		apple: PaletteColorOptions;
	}

	interface Palette extends CustomPalette {}

	interface PaletteOptions extends CustomPalette {}
}

export const paletteTheme: ThemeOptions = {
	palette: {
		primary: {
			main: "#C69214",
		},
		secondary: {
			main: "#e1ff67",
		},
		apple: {
			main: "#FFF",
		},
		warning: {
			main: "#fff100",
		},
		info: {
			main: "#00d4dc",
		},
		grey: {
			"50": "#F2F2F5",
			"100": "#E5E5EA",
			"200": "#C7C7CC",
			"300": "#AEAEB2",
			"400": "#8E8E93",
			"500": "#636366",
			"600": "#48484A",
			"700": "#3A3A3C",
			"800": "#2C2C2E",
			"900": "#1C1C1E",
			A100: "#f5f5f5",
			A200: "#eeeeee",
			A400: "#bdbdbd",
			A700: "#616161",
		},
		background: {
			default: "#FFFFFF",
			paper: "#FFFFFF",
			surface3: "rgba(197, 26, 32, 0.1)",
			widget: "#f0f3f7",
		},
		error: {
			contrastText: "#fff",
			dark: "rgb(170, 46, 37)",
			light: "rgb(246, 104, 94)",
			main: "#f44336",
		},
		success: {
			contrastText: "rgba(0, 0, 0, 0.87)",
			dark: "rgb(16, 140, 79)",
			light: "rgb(70, 211, 141)",
			main: "#18C971",
		},
		text: {
			disabled: "rgba(0, 0, 0, 0.38)",
			primary: "#101010",
			secondary: "#8E8E93",
		},
	},
	components: {
		QuestionCardComponent: {
			styleOverrides: {
				QuestionHeaderRoot: {
					paddingTop: 0,
					gridGap: 2,
				},
			},
		},
		QuestionCardButton: {
			defaultProps: {
				disableRipple: true,
			},
			styleOverrides: {
				BorderStatusCorrect: {
					borderRadius: 0,
				},
				BorderStatusWasCorrect: {
					borderRadius: 0,
				},
				BorderStatusIncorrect: {
					borderRadius: 0,
				},
				BorderStatusSelected: {
					borderRadius: 0,
				},
				BorderStatusDisabled: {
					borderRadius: 0,
				},
				BorderStatusDefault: {
					borderRadius: 0,
				},
			},
		},
		MuiChip: {
			styleOverrides: {
				root: {
					fontSize: "11px",
					fontFamily: "var(--fontFamilyBase)",
					color: "#AEAEB2",
					fontWeight: 500,
				},
				colorPrimary: {
					backgroundColor: "rgba(33, 150, 243, .1)",
					color: "#2196F3",
				},
				colorSecondary: {
					color: "rgb(255, 0, 255)",
					backgroundColor: "rgba(255, 0, 255, 0.1)",
				},
				colorSuccess: {
					color: "rgba(24, 201, 113, 1)",
					backgroundColor: "rgba(24, 201, 113, 0.10)",
				},
				colorError: {
					color: "rgba(244, 67, 54, 1)",
					backgroundColor: "rgba(197, 26, 32, 0.10)",
				},
				colorInfo: {
					color: "rgb(0, 255, 255)",
					backgroundColor: "rgba(0, 255, 255, 0.1)",
					fontSize: 14,
				},
			},
		},
		MuiButton: {
			defaultProps: {
				disableElevation: true,
			},
			styleOverrides: {
				root: {
					textTransform: "none",
					borderRadius: 4,
					":disabled": {
						color: "#000",
						background: "#FF00FF",
						opacity: 0.3,
					},
				},
				contained: {
					textTransform: "uppercase",
					color: "#fff",
					":disabled": {
						color: "#FFFFFF",
						background: "#202020",
						opacity: 0.3,
					},
				},
				containedSecondary: {
					background: "#00ffff",
					border: "2px solid #00ffff",
					"&:hover": {
						background: "#3abdbd",
					},
				},
				outlined: {
					textTransform: "uppercase",
					color: "#000",
				},
			},
		},
		MuiSvgIcon: {
			styleOverrides: {
				colorSuccess: {
					color: "#18C971",
				},
				colorError: {
					color: "#F44336",
				},
				colorInfo: {
					color: "#2196F3",
				},
				colorAction: {
					color: "#000",
				},
				colorSecondary: {
					color: "#00ffff",
				},
			},
		},
		MuiTextField: {
			styleOverrides: {
				root: {
					borderRadius: "4px",
					"& .MuiOutlinedInput-root": {
						"& fieldset": {},
						"&.Mui-focused fieldset": {
							borderTop: "none",
							borderLeft: "none",
							borderRight: "none",
							borderBottom: "2px solid #121212",
						},
					},
				},
			},
		},
		MuiTabs: {
			styleOverrides: {
				root: {
					textTransform: "none",
				},
				flexContainer: {
					borderBottom: "2px solid gray",
				},
			},
		},
		MuiTab: {
			styleOverrides: {
				root: {
					textTransform: "none",
					fontFamily: "var(--fontFamilySecond)",
					"&.Mui-selected, .active": {
						color: "#000",
						borderBottom: "2px solid #C69214",
					},
				},
			},
		},
		MuiAvatar: {
			styleOverrides: {
				root: {
					background: "#FFF",
					color: "var(--primaryColor)",
				},
			},
		},
		MuiTableHead: {
			styleOverrides: {
				root: {
					background: "var(--primaryColor)",
					color: "var(--textColor4)",
				},
			},
		},

		MuiTableRow: {
			styleOverrides: {
				root: {
					"&.own-user": {
						background: "#C69214",
						"&: hover": {
							background: "#C69214",
						},
					},
				},
			},
		},
		MuiTableCell: {
			styleOverrides: {
				root: {
					fontWeight: 500,
					padding: "5px 16px 5px 20px",
					".own-user &": {
						color: "#fff",
						"&: hover": {
							color: "#fff",
						},
					},
				},
			},
		},
		MuiSnackbar: {
			defaultProps: {
				anchorOrigin: {
					horizontal: "right",
					vertical: "bottom",
				},
				autoHideDuration: 5000,
			},
		},
		MuiSnackbarContent: {
			styleOverrides: {
				root: {
					background: "#fff",
					color: "#000",
				},
			},
		},
		MuiList: {
			styleOverrides: {
				root: {},
			},
		},
	},
};
