export const Bindings = {
	JSONProvider: Symbol("JSONProvider"),
	AuthApiProvider: Symbol("AuthApiProvider"),
	ApiHTTPClient: Symbol("ApiHTTPClient"),
	UserApiProvider: Symbol("UserApiProvider"),
	PasswordApiProvider: Symbol("PasswordApiProvider"),
	JsonHTTPClient: Symbol("JsonHTTPClient"),
	ContentJsonHTTPClient: Symbol("ContentJsonHTTPClient"),
	CountriesStore: Symbol("CountriesStore"),
	RoundsStore: Symbol("RoundsStore"),
	PlayersStore: Symbol("PlayersStore"),
	SquadsStore: Symbol("SquadsStore"),
	UserStore: Symbol("UserStore"),
	LocalizationStore: Symbol("LocalizationStore"),
	StaticContentStore: Symbol("StaticContentStore"),
	ModalsStore: Symbol("ModalsStore"),
	AuthController: Symbol("AuthController"),
	LoginController: Symbol("LoginController"),
	RegistrationController: Symbol("RegistrationController"),
	MyAccountController: Symbol("MyAccountController"),
	SecretGateController: Symbol("SecretGateController"),
	HelpController: Symbol("HelpController"),
	HelpListController: Symbol("HelpListController"),
	ContactUsController: Symbol("ContactUsController"),
	ChecksumStore: Symbol("ChecksumStore"),
	LiveScoreController: Symbol("LiveScoreController"),
	ForgotPasswordController: Symbol("ForgotPasswordController"),
	ResetPasswordController: Symbol("ResetPasswordController"),
	SessionController: Symbol("SessionController"),
	BootstrapController: Symbol("BootstrapController"),
	ModalLoginController: Symbol("ModalLoginController"),
	ModalRegistrationController: Symbol("ModalRegistrationController"),
	FormLoginController: Symbol("FormLoginController"),
	FormRegistrationController: Symbol("FormRegistrationController"),

	GraphQLClient: Symbol("GraphQLClient"),
	GameplayApiProvider: Symbol("GameplayApiProvider"),
	// GameplayGraphQLProvider: Symbol("GameplayGraphQLProvider"),
	LocalizationApiProvider: Symbol("LocalizationApiProvider"),
	GameplayStore: Symbol("GameplayStore"),
	StandingsStore: Symbol("StandingsStore"),
	ModalsController: Symbol("ModalsController"),
	HomeController: Symbol("HomeController"),
	HeaderController: Symbol("HeaderController"),
	LocalizationController: Symbol("LocalizationController"),
	ContestController: Symbol("ContestController"),
	ContestBannerController: Symbol("ContestBannerController"),
	MyAccountDetailsController: Symbol("MyAccountDetailsController"),
	MyAccountChangePasswordController: Symbol("MyAccountChangePasswordController"),
	LandingController: Symbol("LandingController"),
	ContestQuestionCardController: Symbol("ContestQuestionCardController"),
	ForgotPasswordFormController: Symbol("ForgotPasswordFormController"),
	SecondRegistrationFormController: Symbol("SecondRegistrationFormController"),
	PrizesController: Symbol("PrizesController"),
	StandingsController: Symbol("StandingsController"),
	FormValidationHelper: Symbol("FormValidationHelper"),
	FormValidator: Symbol("FormValidator"),
	RangeSliderController: Symbol("RangeSliderControllers"),
	StandingsApiProvider: Symbol("StandingsApiProvider"),
	ContestRootController: Symbol("ContestRootController"),
};
