import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import {AxiosResponse} from "axios";
import type {IApiResponse, IHttpClientService} from "data/services/http";
import type {IRankingVariables, IRankings} from "data/types/standings";

export interface IStandingsApiProvider {
	fetch: (variables: IRankingVariables) => Promise<AxiosResponse<IApiResponse<IRankings>>>;
}

@injectable()
export class StandingsApiProvider implements IStandingsApiProvider {
	constructor(@inject(Bindings.ApiHTTPClient) private _http: IHttpClientService) {}

	fetch = ({locale, ...params}: IRankingVariables) =>
		this._http.get<IApiResponse<IRankings>>(`pickem/ranking`, params);
}
