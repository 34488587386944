import {IUser} from "data/stores/user/user.store";

export enum ConnextraType {
	HOME = "f2p_pickem_Homepage",
	REGISTRATION_START = "f2p_pickem_Regstart",
	REGISTRATION_CONFIRM = "f2p_pickem_RegConfirm",
	LOGIN = "f2p_pickem_Loggedin",
	PICK_START = "f2p_pickem_PickStart",
	PICK_CONFIRM = "f2p_pickem_PickConfirm",
	FB_SHARE = "f2p_pickem_FBShare",
	TW_SHARE = "f2p_pickem_TWShare",
	TERMS = "f2p_pickem_TermsofUse ",
	PRIVACY_POLICY = "f2p_pickem_PrivacyPolicy",
	CONTESTS_PAGE = "f2p_pickem_ContestsPage",
	TW_GENIUS = "f2p_pickem_TWGeniusSports",
	FB_GENIUS = "f2p_pickem_FBGeniusSports",
	YT_GENIUS = "f2p_pickem_YTGeniusSports",
	IG_GENIUS = "f2p_pickem_IGGeniusSports",
	TK_GENIUS = "f2p_pickem_TKGeniusSports",
	PRIZES = "f2p_pickem_PrizesPage",
	HELP = "f2p_pickem_HelpPage",
	STANDINGS = "f2p_pickem_StandingsPage",
	MY_ACCOUNT = "f2p_pickem_MyAccountPage",
	OPTIN = "f2p_pickem_Optin",
}

const CONNEXTRA_URL = `https://us.connextra.com/dcs/tagController/tag/d1e388891260/`;

export const createConnextraScriptTag = (type: ConnextraType, user?: IUser) => {
	const elementName = "script";
	const SCRIPT_ID = "connextra-tagging";
	document.getElementById(SCRIPT_ID)?.remove();
	const src = `${CONNEXTRA_URL}${type}`;

	const url = new URL(src);

	if (user?.id) {
		url.searchParams.append("AccountID", `${user.id}`);
	}

	const script = document.createElement(elementName);
	script.id = SCRIPT_ID;
	script.src = url.toString();
	script.async = true;
	script.defer = true;

	const firstJS = document.getElementsByTagName(elementName)[0];
	firstJS.parentNode!.insertBefore(script, firstJS);

	return script;
};
