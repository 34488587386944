import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {IUserStore} from "data/stores/user/user.store";
import {action, makeAutoObservable, observable} from "mobx";
import {RequestState} from "data/enums";
import React from "react";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {Bindings} from "data/constants/bindings";
import {AwsAmplifyError} from "data/constants/AwsAmplifyError";

export interface IMyAccountForm extends HTMLFormElement {
	isNotificationsEnabled: HTMLInputElement;
}

export interface IMyAccountDetailsController extends ViewController {
	readonly i18n: ILocalizationStore;

	get isFormSaving(): boolean;

	get isDataLoading(): boolean;

	get error(): string | undefined;

	get avatarImage(): string | undefined | null;

	get profileFields(): Record<string, string>; // TODO: Update or remove

	onChangeNotifications: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;

	onChangeOptInGZ: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
}

@injectable()
export class MyAccountDetailsController implements IMyAccountDetailsController {
	@observable private _formRequestState: RequestState = RequestState.IDLE;
	@observable private _dataLoadingRequestState: RequestState = RequestState.IDLE;

	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore
	) {
		makeAutoObservable(this);
	}

	@observable private _error?: string;

	get error() {
		return this._error;
	}

	get avatarImage() {
		return this.userAvatarImage || this.defaultAvatarImage;
	}

	private get userAvatarImage() {
		return "";
		// return this._userStore.user?.avatar;
	}

	private get defaultAvatarImage() {
		return "";
		// return this._userStore.defaultAvatar?.avatar;
	}

	get isFormSaving() {
		return this._formRequestState === RequestState.PENDING;
	}

	get profileFields() {
		return {};
		// return this._userStore.userUpdateFields;
	}

	get isDataLoading() {
		// return this._dataLoadingRequestState === RequestState.PENDING || !this.profileFields.terms;
		return this._dataLoadingRequestState === RequestState.PENDING;
	}

	@action private onSuccess = (): void => {
		this._formRequestState = RequestState.IDLE;
		// this._modalsStore.showModal(ModalType.ACCOUNT_UPDATED);
	};

	@action private onError = (error: AwsAmplifyError) => {
		this._error = error.message;
		this._formRequestState = RequestState.ERROR;
	};

	@action
	private updateDetails() {
		this._formRequestState = RequestState.PENDING;

		try {
			// await this._userStore.updateUserProfile();
			this.onSuccess();
		} catch (err) {
			this.onError(err as AwsAmplifyError);
		}
	}

	private handleSubmitForm = () => {
		void this.updateDetails();
	};

	onChangeNotifications = (_event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
		// this._userStore.setUserUpdateFields({
		// 	isNotificationsEnabled: checked,
		// });

		this.handleSubmitForm();
	};

	onChangeOptInGZ = (_event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
		// this._userStore.setUserUpdateFields({
		// 	optInGZ: checked,
		// });

		this.handleSubmitForm();
	};

	dispose(): void {
		return;
	}

	@action
	init() {
		return;
		// if (!this.userAvatarImage) {
		// 	await this._userStore.requestUserAvatars();
		// }
	}
}
