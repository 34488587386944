import {action, makeAutoObservable, observable} from "mobx";
import {injectable} from "inversify";
import {IAxiosApiError} from "data/types/api";
import {AxiosError} from "axios";
import {getErrorMessageFromAxiosResponse} from "data/utils";
import {ModalType} from "data/enums";
import {IModalMessage, TModalArguments} from "data/types/modals";

export interface IModalsStore {
	showModal<T>(...args: TModalArguments<T>): void;

	hideModal(): void;

	showError(args: IModalMessage): void;

	showAxiosError(args: AxiosError<IAxiosApiError, unknown> | undefined): void;

	get modal(): ModalType | null;

	get modalContent(): IModalMessage | unknown | null;
}

@injectable()
export class ModalsStore implements IModalsStore {
	@observable _visibleModal: ModalType | null = null;

	constructor() {
		makeAutoObservable(this);
	}

	@observable _modalContent: IModalMessage | unknown | null = null;

	get modalContent(): IModalMessage | unknown | null {
		return this._modalContent;
	}

	get modal(): ModalType | null {
		return this._visibleModal;
	}

	@action hideModal(): void {
		this._visibleModal = null;
		this._modalContent = null;
	}

	@action showModal<T>(...args: TModalArguments<T>): void {
		const [modalType, content = null] = args;

		this._visibleModal = modalType;
		this._modalContent = content;
	}

	public showError(args: IModalMessage): void {
		this.showModal(ModalType.ERROR, args);
	}

	public showAxiosError(args: AxiosError<IAxiosApiError, unknown> | undefined): void {
		if (!args) {
			return;
		}

		this.showError({
			title: "Error",
			message: getErrorMessageFromAxiosResponse(args),
		});
	}
}
