import React, {Fragment, useCallback, useMemo} from "react";
import {useViewController} from "data/services/locator/locator_hook.service";
import {ILocalizationController} from "views/controllers/localization/localization.controller";
import {Box, css, Popover, Stack, useMediaQuery} from "@mui/material";
import {ShareType, SocialNetwork} from "data/enums";
import {ShareUtility} from "data/utils/social_share/social_share.utility";
import {ButtonPure} from "views/components/buttons/buttons.component";
import styled from "@emotion/styled";
import TwitterIcon from "@mui/icons-material/Twitter";
import FacebookIcon from "@mui/icons-material/Facebook";
import {useParams} from "react-router-dom";
import {IContestRouteParams} from "data/types/global";
import {Bindings} from "data/constants/bindings";

export enum ShareDirection {
	FromLeft = "left",
	FromRight = "right",
	FromTop = "top",
	FromBottom = "bottom",
}

const MOBILE_BREAKPOINT = "640px";

interface IDirection {
	direction: ShareDirection;
}

const popupDirectionVariant = {
	[ShareDirection.FromRight]: css`
		margin: 5px 5px 5px 20px;
		&::before {
			left: calc(var(--halfShareArrowSize) * -1);
			top: calc(50% - var(--halfShareArrowSize));
		}
	`,
	[ShareDirection.FromLeft]: css`
		margin: 5px 20px 5px 5px;
		&::before {
			top: calc(50% - var(--halfShareArrowSize));
			right: calc(var(--halfShareArrowSize) * -1);
		}
	`,
	[ShareDirection.FromTop]: css`
		margin: 5px 5px 20px 5px;
		&::before {
			left: calc(50% - var(--halfShareArrowSize));
			bottom: calc(var(--halfShareArrowSize) * -1);
		}
	`,
	[ShareDirection.FromBottom]: css`
		margin: 20px 5px 5px 5px;
		&::before {
			left: calc(50% - var(--halfShareArrowSize));
			top: calc(var(--halfShareArrowSize) * -1);
		}
	`,
} as const;

const SharePopup = styled(Box)<IDirection>`
	background: #fff;
	color: #fff;
	padding: 10px 15px;
	position: relative;
	border-radius: 4px;
	text-align: center;
	filter: drop-shadow(0px 0.1px 0.3px rgba(28, 28, 30, 0.1))
		drop-shadow(0px 1px 2px rgba(28, 28, 30, 0.2));

	&::before {
		--shareArrowSize: 18px;
		--halfShareArrowSize: calc(var(--shareArrowSize) / 2);
		background-color: #fff;
		content: "";
		display: block;
		position: absolute;
		width: var(--shareArrowSize);
		height: var(--shareArrowSize);
		transform: rotate(45deg);
	}

	${(props) => popupDirectionVariant[props.direction]};
`;

const popoverDirectionVariant = {
	[ShareDirection.FromRight]: {
		anchorOrigin: {
			vertical: "center",
			horizontal: "right",
		},
		transformOrigin: {
			vertical: "center",
			horizontal: "left",
		},
	},
	[ShareDirection.FromLeft]: {
		anchorOrigin: {
			vertical: "center",
			horizontal: "left",
		},
		transformOrigin: {
			vertical: "center",
			horizontal: "right",
		},
	},
	[ShareDirection.FromTop]: {
		anchorOrigin: {
			vertical: "top",
			horizontal: "center",
		},
		transformOrigin: {
			vertical: "bottom",
			horizontal: "center",
		},
	},
	[ShareDirection.FromBottom]: {
		anchorOrigin: {
			vertical: "bottom",
			horizontal: "center",
		},
		transformOrigin: {
			vertical: "top",
			horizontal: "center",
		},
	},
} as const;

const ShareCopy = styled.div`
	color: var(--platform-text-dark, #121212);
	text-align: center;
	font-size: 11px;
	font-family: var(--fontFamilyBase);
	font-style: normal;
	font-weight: 500;
	line-height: 14px;
	letter-spacing: 0.22px;
`;

interface IProps extends IDirection {
	component: React.ReactElement;
	message?: string;
	mobileDirection?: ShareDirection;
}

export const ContestShare: React.FC<IProps> = ({
	component,
	direction,
	mobileDirection,
	message = "",
}) => {
	mobileDirection = mobileDirection ?? direction;
	const {i18n} = useViewController<ILocalizationController>(Bindings.LocalizationController);
	const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
	const isOpen = Boolean(anchorEl);
	const isMobile = useMediaQuery(`(max-width: ${MOBILE_BREAKPOINT})`);
	const isSupportMobileShare = "share" in navigator && isMobile;
	const contestId = Number(useParams<IContestRouteParams>().id);

	const shareProps = useMemo(
		() => ({
			message,
			type: ShareType.Contest as const,
			contestId,
			locale: i18n.locale,
		}),
		[contestId, i18n.locale, message]
	);
	const shareTW = useCallback(
		() =>
			ShareUtility.share({
				...shareProps,
				socialNetwork: SocialNetwork.Twitter,
			}),
		[shareProps]
	);

	const shareFB = useCallback(
		() =>
			ShareUtility.share({
				...shareProps,
				socialNetwork: SocialNetwork.Facebook,
			}),
		[shareProps]
	);

	const shareMobile = useCallback(
		() =>
			ShareUtility.share({
				...shareProps,
				socialNetwork: SocialNetwork.Mobile,
			}),
		[shareProps]
	);

	const handleClick = useCallback(
		(event: React.SyntheticEvent<HTMLElement>) => {
			if (isSupportMobileShare) {
				shareMobile();
			} else {
				setAnchorEl(event.currentTarget);
			}
		},
		[isSupportMobileShare, shareMobile]
	);

	const handleClose = useCallback(() => setAnchorEl(null), []);

	const buttonComponent = React.cloneElement(component, {
		onClick: handleClick,
	});

	const dir = isMobile ? mobileDirection : direction;
	return (
		<Fragment>
			{buttonComponent}
			<Popover
				open={isOpen}
				anchorEl={anchorEl}
				onClose={handleClose}
				{...popoverDirectionVariant[dir]}
				PaperProps={{
					style: {
						backgroundColor: "transparent",
						boxShadow: "none",
						borderRadius: 0,
					},
				}}>
				<SharePopup direction={dir}>
					<Stack direction="column" justifyContent="center" alignItems="center" gap={2}>
						<Stack direction="row" justifyContent="center" alignItems="center" gap={2}>
							<ShareCopy>Share Your Picks</ShareCopy>
						</Stack>
						<Stack direction="row" justifyContent="center" alignItems="center" gap={2}>
							<ButtonPure onClick={shareTW}>
								<TwitterIcon style={{color: "#000"}} />
							</ButtonPure>
							<ButtonPure onClick={shareFB}>
								<FacebookIcon />
							</ButtonPure>
						</Stack>
					</Stack>
				</SharePopup>
			</Popover>
		</Fragment>
	);
};
