import React, {ReactNode} from "react";
import {PagePreloader} from "views/components/preloader";
import {useViewController} from "data/services/locator/locator_hook.service";
import {observer} from "mobx-react";
import {IBootstrapController} from "views/components/bootstrap/bootstrap.controller";
import {Bindings} from "data/constants/bindings";
import {ModalLogin} from "views/components/modals/ModalLogin/modalLogin.component";
import {ModalRegistration} from "views/components/modals/ModalRegistration/modalRegistration.component";
import {ModalSuccess} from "views/components/modals/ModalSuccess/modalSuccess.component";

export const Bootstrap: React.FC<{children: ReactNode}> = observer(({children}) => {
	const {isReady} = useViewController<IBootstrapController>(Bindings.BootstrapController);

	return isReady ? (
		<React.Fragment>
			{children}
			<ModalLogin />
			<ModalRegistration />
			<ModalSuccess />
		</React.Fragment>
	) : (
		<PagePreloader />
	);
});
