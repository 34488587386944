import {ThemeOptions} from "@mui/material/styles";

export const checkboxTheme: ThemeOptions = {
	components: {
		MuiCheckbox: {
			styleOverrides: {
				root: {
					"&.Mui-checked": {
						color: "rgba(89, 97, 106, 1)",
					},
				},
			},
		},
	},
};
