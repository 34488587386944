import React, {lazy} from "react";
import {Route, Routes} from "react-router-dom";
import {retryFailLoad} from "data/utils";
import {FooterOnlyLayout} from "views/components/layouts/footer_only_layout.component";
import {NotAuthOnlyRoute} from "views/components/route/not_auth_only_route.component";
import {PrivateRoute} from "views/components/route/private_route.component";
import {ContestRoot} from "views/components/contest/contest_root/contest_root.component";
import {MainLayout} from "views/components/layouts/main_layout.component";

const Home = lazy(retryFailLoad(() => import("views/pages/home/home.page")));
// const Registration = lazy(
// 	retryFailLoad(() => import("views/pages/registration/registration.page"))
// );
// const ForgotPassword = lazy(
// 	retryFailLoad(() => import("views/pages/forgot_password/forgot_password.page"))
// );
// const ResetPassword = lazy(
// 	retryFailLoad(() => import("views/pages/reset_password/reset_password.page"))
// );

const Landing = lazy(retryFailLoad(() => import("views/pages/landing/landing.page")));
const Help = lazy(retryFailLoad(() => import("views/pages/help/help.page")));
const Prizes = lazy(retryFailLoad(() => import("views/pages/prizes/prizes.page")));
const Standings = lazy(retryFailLoad(() => import("views/pages/standings/standings.page")));

const Contest = lazy(retryFailLoad(() => import("views/pages/contest/contest.page")));
const ContestResult = lazy(
	retryFailLoad(() => import("views/pages/contest_result/contest_result.page"))
);
const MyAccount = lazy(retryFailLoad(() => import("views/pages/my_account/my_account.page")));
const NotFound = lazy(retryFailLoad(() => import("views/pages/not_found/not_found.page")));

export const RootRoutes: React.FC = () => (
	<Routes>
		<Route element={<NotAuthOnlyRoute />}>
			<Route element={<FooterOnlyLayout />}>
				<Route index element={<Landing />} />
				<Route path="verify" element={<Landing />} />
				<Route path="registration" element={<Landing />} />
				<Route path="login" element={<Landing />} />
				<Route path="forgot-password" element={<Landing />} />
			</Route>
		</Route>
		<Route element={<MainLayout />}>
			<Route element={<PrivateRoute />}>
				<Route path="standings">
					<Route index element={<Standings />} />
					<Route path=":contestID" element={<Standings />} />
				</Route>
				<Route path="my-account" element={<MyAccount />} />
			</Route>
			<Route path="dashboard" element={<Home />} />
			<Route path="contest/:id" element={<ContestRoot />}>
				<Route index element={<Contest />} />
				<Route path="result" element={<ContestResult />} />
			</Route>
			<Route path="prizes" element={<Prizes />} />
			<Route path="help/*" element={<Help />} />
			<Route path="*" element={<NotFound />} />
		</Route>
	</Routes>
);

export default RootRoutes;
