import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {makeAutoObservable} from "mobx";
import {ModalType} from "data/enums";
import type {IModalsStore} from "data/stores/modals/modals.store";
import type {IModalMessage, TModalArguments} from "data/types/modals";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import {Bindings} from "data/constants/bindings";
import type {IUser, IUserStore} from "data/stores/user/user.store";

export interface IModalsController extends ViewController {
	show: (...args: TModalArguments) => void;
	close: () => void;
	get user(): IUser | undefined;
	get visibleModal(): ModalType | null;
	get modalContent(): IModalMessage | unknown | null;
	get i18n(): ILocalizationStore;
	get isOpen(): boolean;
	isModalOpen: (modal: ModalType) => boolean;
}

@injectable()
export class ModalsController implements IModalsController {
	constructor(
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore,
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.LocalizationStore) public i18n: ILocalizationStore
	) {
		makeAutoObservable(this);
	}

	get user(): IUser | undefined {
		return this._userStore.user;
	}

	get visibleModal() {
		return this._modalsStore.modal;
	}

	get modalContent() {
		return this._modalsStore.modalContent;
	}

	get isOpen(): boolean {
		return this._modalsStore.modal === ModalType.LOGIN;
	}
	public isModalOpen = (modal: ModalType): boolean => {
		return this._modalsStore.modal === modal;
	};
	show = (...args: TModalArguments) => {
		this._modalsStore.showModal(...args);
	};

	close = () => this._modalsStore.hideModal();

	dispose(): void {
		return;
	}

	init(): void {
		return;
	}
}
