import {ViewController} from "data/types/structure";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import {inject, injectable} from "inversify";
import {makeAutoObservable} from "mobx";
import {Bindings} from "data/constants/bindings";
import type {IStaticContentStore, IArticle} from "data/stores/static_content/static_content.store";

export interface IPrizesController extends ViewController {
	i18n: ILocalizationStore;

	get prizes(): IArticle | undefined;
}

@injectable()
export class PrizesController implements IPrizesController {
	get prizes() {
		return this._staticContentStore.prizes;
	}

	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.StaticContentStore)
		public readonly _staticContentStore: IStaticContentStore
	) {
		makeAutoObservable(this);
	}

	init() {
		void this._staticContentStore.fetchStaticContent();
	}

	dispose() {
		return;
	}
}
