import type {ILocalizationStore} from "data/stores/localization/localization.store";
import {action, computed, makeAutoObservable, observable} from "mobx";
import {FORM_VALIDATION_ELEMENT_CLASSNAME} from "data/constants";
import {filter, get, identity, isEmpty, values} from "lodash";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";

type THTMLFormElements = HTMLInputElement | HTMLSelectElement;

interface IErrorDictionary {
	required: string;
	default: string;
	password_mismatch: string;
	byFieldName: {
		terms: string;
		username: string;
		firstName: string;
		lastName: string;
		email: string;
		password: string;
		confirmPassword: string;
	};
}

export interface IFormValidationHelper {
	get formErrors(): Record<string, string>;
	get isValid(): boolean;
	get errors(): IErrorDictionary;

	clearFormFieldError: (fieldName: string) => void;
	setFormFieldError: (fieldName: string, error?: string) => void;
	checkValidity: (form: HTMLFormElement) => boolean;
}

@injectable()
export class FormValidationHelper implements IFormValidationHelper {
	@observable private _formErrors: Record<string, string> = {};

	constructor(@inject(Bindings.LocalizationStore) private i18n: ILocalizationStore) {
		makeAutoObservable(this);
	}

	get formErrors() {
		return this._formErrors;
	}

	@computed get isValid() {
		return isEmpty(filter(values(this._formErrors), identity));
	}

	@computed public get errors() {
		const errorMsgPasswordInvalid = this.i18n.t(
			"form.error.password",
			"The password must contain at least 1 number, 1 special character, 1 uppercase letter"
		);

		return {
			required: this.i18n.t("registration_form.error.required", "This field is required"),
			default: this.i18n.t("form.error.default", "Please fill-in a correct value"),
			password_mismatch: this.i18n.t(
				"form.error.password_mismatch",
				"Confirm password and password are mismatch"
			),
			byFieldName: {
				terms: this.i18n.t("form.error.terms", "Please accept terms & conditions"),
				username: this.i18n.t(
					"form.error.username",
					"Username must contains at least 2 letters and no longer than 100"
				),
				firstName: this.i18n.t(
					"form.error.first_name",
					"First name must contains at least 2 letters and no longer than 100"
				),
				lastName: this.i18n.t(
					"form.error.last_name",
					"Last name must contains at least 2 letters and no longer than 100"
				),
				email: this.i18n.t("form.error.email", "The value must be a valid email"),
				password: errorMsgPasswordInvalid,
				confirmPassword: errorMsgPasswordInvalid,
				oldPassword: errorMsgPasswordInvalid,
			},
		};
	}

	@action clearFormFieldError = (fieldName: string) => {
		this.setFormFieldError(fieldName);
	};

	@action setFormFieldError = (fieldName: string, error = "") => {
		this._formErrors[fieldName] = error;
	};

	checkValidity = (form: HTMLFormElement) => {
		const fields = Array.from(
			form.getElementsByClassName(FORM_VALIDATION_ELEMENT_CLASSNAME)
		) as unknown as THTMLFormElements[];

		fields.forEach((field) => {
			const {name} = field;
			const hasValue =
				field.type === "checkbox" ? (field as HTMLInputElement).checked : field.value;

			if (field.hasAttribute("required") && !hasValue) {
				this.setFormFieldError(name, this.errors.required);
			} else if (!field.validity.valid) {
				const msg = get(this.errors.byFieldName, name, this.errors.default);
				this.setFormFieldError(name, msg);
			}
		});

		return this.isValid;
	};
}
