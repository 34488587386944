/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable sonarjs/no-duplicate-string */

/*
 * Disabled the following rules:
 * @typescript-eslint/naming-convention
 * @typescript-eslint/no-empty-interface
 * @typescript-eslint/no-unused-vars
 * sonarjs/no-duplicate-string
 * as MUI theme override required override interfaces.
 * Also, it has no sense to move out css variables into another variable
 *
 * More details on style overrides here https://mui.com/material-ui/customization/theme-components/#default-props
 */
import {ThemeOptions} from "@mui/material/styles";
import {CSSInterpolation} from "@mui/material";

const primaryButtonHoverState: CSSInterpolation = {
	background: "#F0C839",
	color: "#000",
};

const secondaryButtonHoverState: CSSInterpolation = {
	background: "#000",
	color: "#fff",
};

const primaryStyle: CSSInterpolation = {
	background: "#121212",
	color: "#FFFFFF",
	fontSize: "16px",
	height: "47px",
	fontWeight: 900,
	borderRadius: "4px",
	transition: "0.25s",
	":hover": primaryButtonHoverState,
	":active": primaryButtonHoverState,
	":disabled": {
		background: "#4D4D4D",
		opacity: 0.3,
	},
};

const secondaryStyle = {
	...primaryStyle,
	background: "#F0C839",
	":hover": secondaryButtonHoverState,
	":active": secondaryButtonHoverState,
	":disabled": {
		background: "#C69214",
		opacity: 0.3,
	},
};

export const buttonsTheme: ThemeOptions = {
	components: {
		MuiButton: {
			styleOverrides: {
				root: {
					fontFamily: "Archivo",
					fontWeight: 900,
					fontSize: "16px",
					borderRadius: "4px",
				},
				containedPrimary: {
					color: "#FFF",
					":hover": {
						background: "blue",
					},
				},
				outlinedPrimary: {
					borderColor: "blue",
					color: "blue",
					":hover": {
						borderColor: "blue",
					},
					":disabled": {
						border: "none",
					},
				},
				containedSecondary: {
					color: "#018A8A",
					":hover": {
						background: "#FFF",
					},
				},
				containedError: {
					background: "red",
					color: "#FFF",
				},
			},
			variants: [
				{
					props: {className: "byClassName"},
					style: {
						background: "yellow",
						color: "#000",
						":hover": {
							background: "blue",
						},
					},
				},
				{props: {variant: "contained"}, style: primaryStyle},
				{props: {variant: "contained", color: "info"}, style: secondaryStyle},
			],
		},
	},
};
