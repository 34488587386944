import React, {useCallback} from "react";
import {observer} from "mobx-react";
import {FormLoginComponent} from "views/components/FormLogin/formLogin.component";
import {Modal} from "@mui/material";
import {useViewController} from "data/services/locator";
import CloseIcon from "@mui/icons-material/Close";
import {IModalLoginController} from "views/components/modals/ModalLogin/modalLogin.controller";
import {Bindings} from "data/constants/bindings";
import {ModalCloseButton, ModalContent} from "views/components/modals/common";
import logo from "assets/img/login_logo.png";
import styled from "@emotion/styled";

const Logo = styled.img`
	display: block;

	@media (max-width: 860px) {
		margin: 24px auto 20px auto;
	}
`;
const LoginFormTitle = styled.h4`
	font-family: var(--fontFamilySecond);
	font-size: 16px;
	line-height: 118%;
	font-weight: 500;
	margin-top: 12px;
	margin-bottom: 12px;
`;
export const ModalLogin: React.FC = observer(() => {
	const {close, isOpen} = useViewController<IModalLoginController>(Bindings.ModalLoginController);

	const handleClose = useCallback(
		(event: unknown, reason?: "backdropClick" | "escapeKeyDown") => {
			if (reason === "backdropClick") {
				return;
			}

			close();
		},
		[close]
	);

	return (
		<Modal className="full-screen" open={isOpen} onClose={handleClose}>
			<ModalContent>
				<ModalCloseButton onClick={close}>
					<CloseIcon />
				</ModalCloseButton>
				<Logo src={logo} role="banner" alt="" />
				<LoginFormTitle>Log in to your account</LoginFormTitle>
				<FormLoginComponent />
			</ModalContent>
		</Modal>
	);
});
