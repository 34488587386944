import React, {Fragment, useEffect} from "react";
import {useViewController} from "data/services/locator/locator_hook.service";
import {IContestBannerController} from "views/components/contest/contest_banner/contest_banner.contoller";
import {Bindings} from "data/constants/bindings";
import {Exist} from "views/components/exist/exist.component";
import {
	ContestCountdown,
	ContestCountdownWrapper,
} from "views/components/contest/contest_countdown/contest_countdown.component";
import {observer} from "mobx-react";
import {IContest} from "data/types/contests";

interface IContestDateProps {
	contest: IContest;
	color?: string;
}

export const ContestDate: React.FC<IContestDateProps> = observer(({contest, color}) => {
	const controller = useViewController<IContestBannerController>(
		Bindings.ContestBannerController,
		{contest}
	);

	const {id, dateStart} = contest;

	const {
		i18n,
		setContestComplete,
		updateContest,
		isDateVisible,
		isTimerVisible,
		isOpen,
		isComingSoon,
		// isComplete,
		lockDateString,
	} = controller;

	const isScheduled = isOpen || isComingSoon;

	useEffect(() => {
		updateContest(contest);
	}, [updateContest, contest]);

	return (
		<Fragment>
			<Exist when={isScheduled}>
				<Exist when={isTimerVisible}>
					<ContestCountdown
						contestID={id}
						date={dateStart}
						onComplete={setContestComplete}
						color={color}>
						{i18n.t("contest.date.locks_in", "Locks in")}{" "}
					</ContestCountdown>
				</Exist>
				<Exist when={isDateVisible}>
					<ContestCountdownWrapper color={color}>
						{i18n.t("contest.date.locks_on", "Locks on")} {lockDateString}
					</ContestCountdownWrapper>
				</Exist>
			</Exist>
			<Exist when={!isScheduled}>
				<ContestCountdownWrapper color={color}>Locked</ContestCountdownWrapper>
			</Exist>
		</Fragment>
	);
});
