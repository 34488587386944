import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import {makeAutoObservable} from "mobx";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {ModalType} from "data/enums";

export interface ILoginController extends ViewController {
	openLogin: () => void;
	openRegister: () => void;
}

@injectable()
export class LoginController implements ILoginController {
	constructor(@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore) {
		makeAutoObservable(this);
	}

	openLogin = (): void => {
		this._modalsStore.showModal(ModalType.LOGIN);
	};

	openRegister = (): void => {
		this._modalsStore.showModal(ModalType.REGISTRATION);
	};
}
