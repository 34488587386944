import styled from "@emotion/styled";
import {Button, Link} from "@mui/material";

import React from "react";
import {useViewController} from "data/services/locator/locator_hook.service";
import {Bindings} from "data/constants/bindings";
import {ILocalizationController} from "views/controllers/localization/localization.controller";

export const ButtonLogout: React.FC = () => {
	const {i18n} = useViewController<ILocalizationController>(Bindings.LocalizationController);

	return (
		<Button
			component={Link}
			sx={{fontFamily: "var(--fontFamilySecond)", fontWeight: 700, fontSize: 16}}
			variant="outlined"
			style={{background: "#00ffff", border: "2px solid #00ffff"}}
			href="logout">
			{i18n.t("button.logout.text", "Logout")}
		</Button>
	);
};

const ButtonPrimaryBase = styled(Button)``;

ButtonPrimaryBase.defaultProps = {
	variant: "contained",
};

const ButtonSecondaryBase = styled(ButtonPrimaryBase)``;

ButtonSecondaryBase.defaultProps = {
	color: "info",
};

/**
 * Type casting is required because of MUI issue
 * https://github.com/mui/material-ui/issues/13921#issuecomment-484133463
 */
export const ButtonPrimary = ButtonPrimaryBase as typeof Button;

export const ButtonSecondary = ButtonSecondaryBase as typeof Button;

export const ButtonPure = styled.button`
	padding: 0;
	margin: 0;
	outline: none;
	background: none;
	border: none;
	cursor: pointer;
`;

export const ModalCloseButton = styled(ButtonPure)`
	position: absolute;
	right: 20px;
	top: 20px;
	color: var(--textColor3);
`;

export const LoadMoreButton = styled(ButtonPrimary)`
	width: 100%;
	padding: 20px;
	max-width: 100%;
	min-width: auto;
	font-family: var(--fontFamilyBase);
	color: var(--textColor3);
	font-weight: 400;
`;

export const ButtonPrimaryBlack = styled(ButtonPrimary)`
	background-color: var(--primaryColorBlack);
`;
